import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home';
import City from '../views/City';
import Network from '../views/Network';
import OrderList from '../views/Order/List';
import Order from '../views/Order/Detail';
import Refund from '../views/Order/Refund';
import Rate from '../views/Order/Rate';
import Login from '../views/Login';
import Pay from '../views/Pay';
import Result from '../views/Result';
import Complain from '../views/Order/Complain';
import Code from '../views/Code';

import store from '../store';

import axios from 'axios';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:code',
    component: Code,
    children: [
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: { title: '首页' },
      },
      {
        path: 'city',
        name: 'city',
        component: City,
        meta: { title: '选择城市' },
      },
      {
        path: 'network',
        name: 'network',
        component: Network,
        meta: { title: '网点查询' },
      },
      {
        path: 'login',
        name: 'login',
        component: Login,
      },
      {
        path: 'order',
        name: 'order',
        component: OrderList,
        meta: { login_required: true, title: '我的订单' },
      },
      {
        path: 'order/:id',
        name: 'orderDetail',
        component: Order,
        meta: { login_required: true, title: '订单详情' },
      },
      {
        path: 'order/:id/refund',
        name: 'orderRefund',
        component: Refund,
        meta: { login_required: true, title: '退款申请' },
      },
      {
        path: 'order/:id/rate',
        name: 'orderRate',
        component: Rate,
        meta: { login_required: true, title: '服务评价' },
      },
      {
        path: 'order/:id/complain',
        name: 'orderComplain',
        component: Complain,
        meta: { login_required: true, title: '投诉' },
      },
      {
        path: 'pay/:id',
        name: 'pay',
        component: Pay,
        meta: { login_required: true, title: '订单支付' },
      },
      {
        path: 'result/:id',
        name: 'result',
        component: Result,
        meta: { login_required: true, title: '支付成功' },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function redirect(path) {
  let redirect_uri = encodeURIComponent('https://h5.finequality1.com' + path); //正式环境，打包前记得更改
  //let redirect_uri = encodeURIComponent('https://bank.h5.amywechat.com' + path); //测试环境
  location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxae44fdee0044cc53&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base#wechat_redirect`;
}

router.beforeEach((to, from, next) => {
  if (to.params.code === 'NHHN') {
    // location.href = "http://wh.finequality1.com";

    let title = to.meta.title;

    if (title) {
      sensors.quick('autoTrackSinglePage', {
        $title: '河南_城市专区_信用卡5元美发', // + '_' + to.meta.title,
        firefly_prod_name: "5元美发",
        firefly_prod_type: "本地生活场景",
        firefly_prod_code: "103881606400100",
        pageprodinfo_1: "信用卡5元美发",
      });
    }
  }

  if (to.params.code === 'SXNHWY') {
    let title = to.meta.title;

    if (title) {
      sensors.quick('autoTrackSinglePage', {
        $title: '陕西_本地优惠_信用卡专区_10元美发' + '_' + to.meta.title,
      });
    }
  }

  if (to.params.code === 'NHJS') {
    if (to.name === 'home') {
      // const num = window.history.length - 4;

      // window.history.go(-num);

      localStorage.clear();

      if (to.query.custPhone) {
        store.commit('setToken', { custPhone: to.query.custPhone });
      }

      if (to.query.openid) {
        store.commit('setToken', { nhjsOpenid: to.query.openid });
      }
      if (to.query.act) {
        store.commit('setToken', { act: to.query.act || 'xf' });
      }
    }

    let custPhone = localStorage.getItem('custPhone');
    let nhjsOpenid = localStorage.getItem('nhjsOpenid');

    if (to.meta.login_required) {
      if (/micromessenger/i.test(navigator.userAgent)) {
        if (to.query.code) {
          axios({
            url: `/api/v1/h5/account/${to.query.code}/oauth`,
            params: { code: to.params.code, phone: custPhone || '' },
          }).then(({ code, data, msg }) => {
            if (code === 0) {
              // if (data.id) {
              //   store.commit('setToken', { token: data.id });
              //   next();
              // } else {
              store.commit('setOpenId', data.openId);
              axios({
                url: '/api/v1/h5/project',
                params: { code: to.params.code },
              }).then((res) => {
                if (res.code === 0) {
                  axios({
                    url: `/api/v1/h5/Account/${res.data.id}/Login`,
                    method: 'POST',
                    data: {
                      account: custPhone,
                      nhjsOpenid: nhjsOpenid,
                      openId: data.openId,
                    },
                  }).then(({ code, data, msg }) => {
                    if (code) {
                    } else {
                      store.commit('setToken', { token: data });
                      next();
                    }
                  });
                }
              });
            } else {
              redirect(to.path);
              // if(store.state.custPhone){
              //   next();
              // }else {
              //   let redirect_uri = `${to.path}?custPhone=${to.query.custPhone}&openid=${to.query.openid}&act=${to.query.act || ''}`;
              //   redirect(redirect_uri);
              // }
            }
          });
        } else {
          redirect(to.fullPath);
        }
        // }
      } else {
        router.push({ name: 'login', params: { code: to.params.code } });
      }
    } else {
      next();
    }
    // }
  }
  else if (to.params.code === 'NHHN') {
    // 获取后台配置，主要判断当前用户是否需要强制登录
    axios({
      url: '/api/v1/h5/project',
      params: { code },
    }).then((res) => {
      if (res.code === 0) {
        store.commit('setState', res.data);

        // alert('地址'+to.name);

        if (false) {
        // if (store.getters.isLogin && !res.data.isNeedLogin) {
          next();
        } else {
          if (to.name === 'home') {
            if (/bankabc/i.test(navigator.userAgent)) {
              // alert(JSON.stringify(to.query));

              // alert('phone'+ to.query.phone);

              if (to.query.customerId) {
                store.commit('setToken', { token: to.query.customerId });
                next();
              }
              else if (to.query.phone) {
                store.commit('setToken', { phone: to.query.phone, custId: to.query.custId });
    
                router.push({ name: 'login', params: { code: to.params.code, phone: to.params.phone, custId: to.params.custId } });
              } else {
                if (to.query.error) {
                  // alert(JSON.stringify(to.query))
                  alert(to.query.error + ',请返回重试')
                  return
                } else {
                  // alert('2');
                  var obj = {
                    client_id: "0cd2c080-3ec4-41bf-b45c-c24801427cca",
                    redirect_uri: "http://yz.finequality1.com/NHHN/Authorize",
                    state: "123456",
                    scope: "openid,phone,custinfo",
                    response_type: "code",
                  };

                  // alert('https://www.abchina.com/luascript/oauthLogin/' + JSON.stringify(obj));
    
                  window.location.href = 'https://www.abchina.com/luascript/oauthLogin/' + JSON.stringify(obj);
                  // window.location.href = 'https://openbank.abchina.com/OAuthServer/OAuth/Authorize?client_id=' + obj.client_id + '&redirect_uri=' + obj.redirect_uri + '&state=' + obj.state + '&scope=' + obj.scope + '&response_type=' + obj.response_type;
                }
              }
            }
            else {
              router.push({ name: 'login', params: { code: to.params.code } });
            }
          } else {
            next();
          }
        }
      }
    });

    
  }
  else if (to.params.code === 'SXNHWY') {
    if (to.name === 'home') {
      if (/bankabc/i.test(navigator.userAgent)) {
        if (to.query.customerId) {
          store.commit('setToken', { token: to.query.customerId });
          next();
        } else {
          if (to.query.error) {
            alert(to.query.error + ',请返回重试')
            return
          } else {
            var obj = {
              sign: to.query.sign,
              openId: to.query.openId,
              cityCode: to.query.cityCode,
              name: to.query.name,
            };
            window.location.href = `https://yz.finequality1.com/NHSX/Authorize?sign=${obj.sign}&openId=${obj.openId}&cityCode=${obj.cityCode}&name=${obj.name}`;
          }
        }
      }
      else {
        router.push({ name: 'login', params: { code: to.params.code } });
      }
    } else {
      next();
    }
  }
  else {
    if (store.getters.isLogin) {
      next();
    } else {
      if (to.meta.login_required) {
        if (/micromessenger/i.test(navigator.userAgent)) {
          if (to.query.code) {
            axios({
              url: `/api/v1/h5/account/${to.query.code}/oauth`,
              params: { code: to.params.code },
            }).then(({ code, data, msg }) => {
              if (code === 0) {
                if (data.id) {
                  store.commit('setToken', { token: data.id });
                  next();
                } else {
                  store.commit('setOpenId', data.openId);
                  router.push({
                    name: 'login',
                    params: { code: to.params.code },
                  });
                }
              } else {
                redirect(to.path);
              }
            });
          } else {
            redirect(to.fullPath);
          }
        } else {
          router.push({ name: 'login', params: { code: to.params.code } });
        }
      } else {
        next();
      }
    }
  }
});

// router.afterEach((to, from) => {
//   if (to.params.code === 'NHHN') {
//     let title = to.meta.title;

//     if (title) {
//       sensors.quick('autoTrackSinglePage', {
//         $title: '河南_城市专区_信用卡5元美发', // + '_' + to.meta.title,
//         firefly_prod_name: "5元美发",
//         firefly_prod_type: "本地生活场景",
//         firefly_prod_code: "103881606400100",
//         pageprodinfo_1: "本地特色",
//       });
//     }
//   }
// })

export default router;
