var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('nav-bar',{attrs:{"title":"我的订单","left-arrow":""},on:{"click-left":_vm.goBack}}),_c('van-tabs',{attrs:{"color":_vm.color},on:{"change":_vm.onChange},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[(_vm.code === 'NHJS')?_c('van-tab',{attrs:{"title":"未支付","name":"0"}}):_vm._e(),_c('van-tab',{attrs:{"title":"未消费","name":"1"}}),_c('van-tab',{attrs:{"title":"已消费","name":"2"}}),_c('van-tab',{attrs:{"title":"已退款","name":"3"}})],1),(_vm.code === 'NHJS' && _vm.type === '0' && _vm.list.length > 0)?_c('div',{staticStyle:{"width":"100%","height":"30px","line-height":"30px","text-align":"center","font-size":"12px","color":"#575757"}},[_vm._v(" 未支付订单限15分钟内完成支付，否则领劵无效 ")]):_vm._e(),_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item){return _c('van-panel',{key:item.id,staticClass:"order-panel",style:([
        {
          marginTop:
            _vm.code === 'NHJS' && _vm.type === '0' && _vm.list.length > 0
              ? '0px'
              : '10px',
        },
        {
          marginBottom:
            _vm.code === 'NHJS' && _vm.type === '0' && _vm.list.length > 0 ? '10px' : '0',
        } ]),attrs:{"title":("下单时间：" + (_vm.type === '0' ? item.createTime : item.paymentTime)),"status":item.statusText}},[_c('van-card',{attrs:{"price":("" + (item.paymentPrice.toFixed(2))),"title":item.projectTitle,"thumb":item.pictures},on:{"click":function($event){return _vm.goDetail(item.id)}}}),(item.status === 1 || item.status === 3)?_c('div',{attrs:{"slot":"footer"},slot:"footer"},[(item.status === 1)?_c('v-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.onCancel(item.id)}}},[_vm._v(" 取消订单 ")]):_vm._e(),(item.status === 1)?_c('v-button',{attrs:{"size":"small","to":("/" + _vm.code + "/Pay/" + (item.serviceId) + "?projectId=" + (item.projectId))}},[_vm._v(" 立即支付 ")]):_vm._e(),(item.status === 3)?_c('v-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.goRate(item.id)}}},[_vm._v(" 评价 ")]):_vm._e(),(item.status === 3)?_c('v-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.goComplain(item.id)}}},[_vm._v(" 投诉 ")]):_vm._e()],1):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }