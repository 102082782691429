import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import hideWx from "./utils/index";


Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://yz.finequality1.com';
  // axios.defaults.baseURL = 'https://bank.amywechat.com';
}

axios.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
);

let code = location.pathname.split('/')[1];
if (code) {
    let token = localStorage.getItem(code);
    if (token) {
      store.commit('setToken', { code, token });
    }
}

Vue.prototype.$api = {
	hideWxMenu: hideWx.hideWxMenu,
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
