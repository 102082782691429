<template>
  <div class="container">
    <nav-bar title="选择城市" left-arrow @click-left="goBack" />
    <van-cell
      icon="location-o"
      v-if="location"
      :title="location.name"
      @click="onSelect(location.code, location.name)"
    />
    <div
      v-for="[provinceCode, province] in Object.entries(regions.province_list)"
      :key="provinceCode"
    >
      <div class="title">{{ province }}</div>
      <van-cell
        v-for="[code, city] in Object.entries(regions.city_list).filter((x) =>
          x[0].startsWith(provinceCode.slice(0, 2))
        )"
        :key="code"
        :title="city"
        @click="onSelect(code, city)"
      />
    </div>
  </div>
</template>

<script>
import { Cell, Dialog } from 'vant';
import { mapState } from 'vuex';
import NavBar from '@/components/NavBar';
import axios from 'axios';

export default {
  components: {
    [Cell.name]: Cell,
    [Dialog.name]: Dialog,
    [NavBar.name]: NavBar,
  },
  data() {
    return {
      location: {},
    };
  },
  computed: {
    ...mapState(['regions', 'code']),
  },
  mounted: function () {
    this.getLocation();
    if (this.code === 'NHJS') {
      // this.$api.hideWxMenu()
      this.hideWxMenu();
    }
  },
  methods: {
    getWxConfig(data) {
      window.wx.config({
        debug: false,
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        jsApiList: ['getLocation', 'hideMenuItems', 'hideAllNonBaseMenuItem'],
      });
    },
    getLocationAddress(lat, lng) {
      axios({
        url: `/account/Geocoder?location=${lat},${lng}`,
        method: 'GET',
      }).then(({ status, result }) => {
        if (status === 0) {
          this.location = {
            code: result.ad_info.adcode,
            name: result.ad_info.city,
          };
        }
      });
    },
    getLocation() {
      if (/micromessenger/i.test(navigator.userAgent)) {
        let href = decodeURIComponent(window.location.href);
        axios({
          method: 'GET',
          url: `/wechat/generateShareSign?url=${href}`,
        }).then(({ code, count, data, msg }) => {
          if (code) {
            return getLocationError();
          }
          this.getWxConfig(data);
          let that = this;
          wx.ready(() => {
            wx.getLocation({
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: (res) => {
                that.getLocationAddress(res.latitude, res.longitude);
              },
              fail: (err) => {
                // getLocationError();
              },
            });
          });
        });
      } else {
        const geolocation = new qq.maps.Geolocation(
          'NFMBZ-6RUWS-LJDOP-62EZB-PAYKO-XOFA4',
          'yunzhi'
        );

        geolocation.getLocation(({ lat, lng, adcode, city }) => {
          this.location = { code: adcode, name: city };
        });
      }

      function getLocationError() {
        Dialog.alert({
          title: '提示',
          message: '未能定位到你的位置信息，请开启定位后重试',
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    onSelect(code, name) {
      this.$store.commit('setCity', { code, name });
      this.$router.push({ name: 'network', query: this.$route.query });
    },
    hideWxMenu() {
      if (/micromessenger/i.test(navigator.userAgent)) {
        let href = encodeURIComponent(window.location.href);
        axios({
          method: 'GET',
          url: `/wechat/generateShareSign?url=${href}`,
        }).then(({ code, count, data, msg }) => {
          if (code) {
            return;
          }
          this.getWxConfig(data);
          wx.ready(() => {
            wx.hideAllNonBaseMenuItem();

            // wx.hideMenuItems({
            //   menuList: [
            //     'menuItem:share:timeline',
            //     'menuItem:copyUrl',
            //     'menuItem:share:appMessage',
            //     'menuItem:share:qq',
            //     'menuItem:share:weiboApp',
            //     'menuItem:favorite',
            //     'menuItem:share:facebook',
            //     'menuItem:share:QZone',
            //     'menuItem:editTag',
            //     'menuItem:delete',
            //     'menuItem:copyUrl',
            //     'menuItem:originPage',
            //     'menuItem:readMode',
            //     'menuItem:openWithQQBrowser',
            //     'menuItem:openWithSafari',
            //     'menuItem:share:email',
            //     'menuItem:share:brand',
            //   ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
            // });
          });
          wx.error(function (res) {
            wx.hideAllNonBaseMenuItem();
            console.info(res);
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
  background-color: #fafafa;
}
.title {
  color: #666;
  font-size: 13px;
  padding: 16px 0 8px 16px;
}
</style>
