<template>
  <div>
    <div v-if="code !== 'NHJS'">
      <nav-bar :title="title" :left-text="isLogin ? '退出' : ''" @click-left="logout" />
    </div>
    <div v-else>
      <nav-bar :title="title" />
    </div>

    <van-swipe class="swipe" :autoplay="3000">
      <van-swipe-item v-for="(banner, index) in banners" :key="index">
        <img :src="banner" :alt="index" />
      </van-swipe-item>
    </van-swipe>
    <div v-if="isLogin && code === 'NHHN' && customerPhone">
      <!-- <div style="
          display: flex;
          justify-content: space-between;
          padding: 8px 10px;
          font-size: 14px;
          color: #fff;
        " :style="{ backgroundColor: color }">
        <span>您的手机号码：{{ customerPhone }} </span>
        <span @click="logout">退出</span>
      </div>-->
    </div>
    <div v-if="code === 'SXNHWY'" style="background-color:#1092af">
      <!-- <van-tabs
        v-model="active"
        :color="color"
        :ellipsis="false"
        @change="onTabChange"
      >
        <van-tab
          v-for="service in services"
          :key="service.id"
          :title="service.title"
        />
      </van-tabs> -->
      <div class="navs">
        <!-- <div v-if="
          code !== 'NHHN' &&
          code !== 'NHJS' &&
          code !== 'SXNHCS' &&
          code !== 'SXNHWY'
        ">
          <div class="alert" v-if="showRemainCount">
            剩余数量：{{ services[active].remainCount }}
          </div>
        </div>
        <div class="alert" v-if="isLogin && services[active].isWhiteList">
          权益次数：{{ availableCount }}
        </div> -->
        <v-button block class="nav" v-if="services[active]" @click="buy(services[active], $event)">
          立即购买
        </v-button>
        <v-button block hairline plain class="nav" v-if="services[active]" @click="onShow">
          网点查询
        </v-button>
        <v-button block hairline plain class="nav" @click="goOrder">
          我的订单
        </v-button>
      </div>
      <div class="intro">
        <div>
          <van-divider style="color: #ffffff">权益使用流程</van-divider>
          <div style="margin: 0 15px 30px">
            <van-row>
              <van-col span="6" justify="center">
                <div class="circle-step">1</div>
                <div class="step-text" style="color: #fff">
                  查看服务网点前往门店
                </div>
              </van-col>
              <van-col span="3">
                <div class="step-arrow">
                  <van-icon name="arrow" color="#ffffff" style="color: #fff" />
                </div>
              </van-col>
              <van-col span="6">
                <div class="circle-step">2</div>
                <div class="step-text" style="color: #fff">提前出示二维</div>
                <div class="step-text" style="margin-top: 0; color: #fff">
                  码或电子劵码
                </div>
              </van-col>
              <van-col span="3">
                <div class="step-arrow">
                  <van-icon name="arrow" />
                </div>
              </van-col>
              <van-col span="6">
                <div class="circle-step" style="color: #fff">3</div>
                <div class="step-text" style="color: #fff">完成消费</div>
              </van-col>
            </van-row>
          </div>
        </div>
      </div>
      <div v-if="code !== 'SXNHWY'">
        <div v-if="services[active]" class="intro">
          <div v-for="(rule, index) in services[active].rules" :key="index">
            <van-divider :style="{ color: color }">{{ rule.name }}</van-divider>
            <div class="intro-content" v-html="rule.content.replace(/\n/g, '<br/>')"></div>
          </div>
        </div>
      </div>
      <div v-if="code === 'SXNHWY'">
        <div class="intro" style="margin-bottom: 0; padding-bottom: 10px">
          <van-divider style="color: #fff">活动内容</van-divider>
          <div class="intro-content" style="color: #f5f5f5">
            <div>
              <span style="font-weight: 700; color: #f6c037">【活动时间】：</span>2023年02月08日~2023年02月28日
            </div>
            <div>
              <span
                style="font-weight: 700; color: #f6c037">【活动对象】：</span>发卡行和掌银注册归属行均为农行陕西省分行的信用卡消费达标个人客户（准贷记卡、商务卡不参加本活动）。
            </div>
            <div>
              <span style="font-weight: 700; color: #f6c037">【活动内容】：</span>
              活动期的上一个自然月（自然月指月初1日起至当月末），持卡人单张信用卡有效消费金额达标后（详见规则及说明），持卡人可于活动期内每月6日起获得“10元美发”优惠权益购买资格。优惠券购买成功后，可在陕西省内指定门店凭有效优惠券享受标准美发服务。
              <div style="text-indent: 2em">
                持卡人可在掌银“信用卡专区”内使用获得的优惠权益，选择参加不同的活动项目，每参加1次活动自动核销1次优惠权益资格，至多可选3个活动项目参加。
              </div>
            </div>
            <div>
              <span style="font-weight: 700; color: #f6c037">【活动范围】：</span>活动参与及权益使用范围均限陕西省内。
            </div>
            <div style="
                text-align: center;
                font-weight: 700;
                color: #ffffff;
                margin-top: 5px;
              ">
              参与活动中如遇到购买、使用等问题，请咨询客服
            </div>
            <div style="text-align: center; font-weight: 700; color: #ffffff">
              客服电话：<a href="tel:4007889395" style="color: #f6c037">400-788-9395</a>
            </div>
          </div>
          <van-divider style="color: #ffffff">活动规则</van-divider>
          <div class="intro-content" style="color: #f5f5f5">
            <div style="text-indent: 2em">
              <span>1.</span>本活动期共投放350份优惠券，先购先得，购完即止。已购买的优惠券不支持退换，不可兑换现金，不设找零，不可叠加使用。
              <span
                style="font-weight: 700; color: #ffffff">优惠券自购买日（含）起30日内有效，过期未使用的视为用户主动放弃，优惠资格不再予以补发，原购券款项将退还至原支付账户。</span>
            </div>
            <div style="text-indent: 2em">
              <span style="font-weight: 700; color: #ffffff">2.有效消费金额的达标标准及权益配置具体为：</span><span
                style="color: #ffffff; font-weight: 700">有效消费满888元（含），每卡每月可获1次优惠权益；满1888元（含），每卡每月可获2次优惠权益；满2888元（含），每卡每月可获3次优惠权益。每卡每月至多可获得3次优惠权益资格。</span>
              <span style="color: #ffffff; font-weight: 700">（有效消费达标金额实行动态调整，敬请关注活动规则及页面通告等）。</span>
            </div>
            <div style="text-indent: 2em">
              <span>3.</span>持卡人可在掌银“信用卡专区”内使用获得的优惠权益，选择参加不同的活动项目，每参加1次活动自动核销1次优惠权益资格，至多可选3个活动项目参加。
            </div>
            <div style="text-indent: 2em">
              <span
                style="font-weight: 700; color: #ffffff">4.有效消费不包含ETC通行费、社保/医保/保费等代缴费、燃气、水、电等代收付交易；不包含满足活动条件的消费发生撤销、退货、退款以及收到的返现等。</span>
              消费交易时间、金额以农行系统记录的账务完成时间、完成金额为准。
            </div>
            <div style="text-indent: 2em">
              <span style="color: #ffffff; font-weight: 700">5.本活动仅限标准美发服务。</span><span
                style="font-weight: 700; color: #ffffff">所指标准的美发服务包括“洗发+特级发型精致剪发+吹发造型”，仅适用于商户指定发型师，全程需当日一次性体验完毕所有服务项目。若超出标准，则商户有权拒绝承兑，或由客户与商户协商按照商户要求补足差价。</span>
            </div>
            <div style="text-indent: 2em">
              <span>6.</span>每个活动期投放的优惠券数量，实施动态调整，具体请以每个活动期标明的数量为准。
            </div>
          </div>
          <van-divider style="color: #ffffff">购券及用券流程</van-divider>
          <div class="intro-content" style="color: #f5f5f5">
            <div style="font-weight: 700; color: #f6c037; text-indent: 2em">
              （一）购券：
            </div>
            <div style="text-indent: 2em">
              <span style="font-weight: 700; color: #ffffff">1.掌银标准版：【</span>掌银首页→城市专区→乐享三秦→信用卡专区→10元美发→点击“立即购买”<span
                style="font-weight: 700; color: #ffffff">】</span>。
            </div>
            <div style="text-indent: 2em">
              <span
                style="font-weight: 700; color: #ffffff">2.掌银大字版/乡村版：【</span>掌银首页→全部→向下翻页至“生活”→城市专区→乐享三秦→信用卡专区→10元美发→点击“立即购买”<span
                style="font-weight: 700">】</span>。
            </div>
            <div style="font-weight: 700; color: #f6c037; text-indent: 2em">
              （二）已购买的票券查询：
            </div>
            <div style="text-indent: 2em">
              “10元美发”活动页面首页→点击“我的订单”→未消费/已消费/已退款→查看票券信息。
            </div>
            <div style="font-weight: 700; color: #f6c037; text-indent: 2em">
              （三）用券：
            </div>
            <div style="text-indent: 2em">
              活动页面点击“网点查询”查看合作商户，前往至合作美发商户门店，<span
                style="font-weight: 700; color: #ffffff">报“赟质美发”</span>，出示服务二维码，商家核销票券成功后，为顾客提供标准美发服务。
            </div>
          </div>
          <van-divider style="color: #ffffff">其他事项</van-divider>
          <div class="intro-content" style="color: #f5f5f5">
            <div style="text-indent: 2em">
              （一）<span
                style="font-weight: 700; color: #ffffff">本次活动的服务提供商为南京蔚顺信息科技有限公司（以下简称“蔚顺科技”），由其提供相关服务并负责。持卡人因商品及服务质量引发的任何索赔、质询及投诉，由服务提供商处理及解决，农行陕西省分行将为持卡人提供必要的协助。</span>
              参与活动的美发门店为陕西省内与蔚顺科技合作的门店，具体以活动页面展示或蔚顺科技官方平台发布为准。如有疑问可致电蔚顺科技
              <span style="font-weight: 700; color: #ffffff">客服热线<a href="tel:4007889395" style="color: #f6c037">
                  400-788-9395</a>，坐席时间08:30-19:00</span>。
            </div>
            <div style="text-indent: 2em">
              （二）每月1-5日为系统更新时间，期间不支持优惠券购买。
            </div>
            <div style="text-indent: 2em">
              （三）持卡人自活动周期消费统计时点起至权益使用之日，卡片及账户等均需保持正常状态。如持卡人存在有下述任何情况，其参加本次活动的资格将被取消，包括但不限于：当前卡片/账户出现逾期、
              冻结、止付等非正常状态，或有进行虚假、欺诈、套现等违法交易，或用于经营性目的、与商户恶意串通以各种形式损害农行权益等；违反《中国农业银行金穗信用卡章程》、《中国农业银行金穗信用卡领用合约（个人卡）》及其他法律、法规等相关规定。
            </div>
            <div style="text-indent: 2em">
              （四）由于持卡人移动设备的软硬件、通讯等问题，未预留正确手机号码、自行变更手机号码、手机号码失效、手机停机等非农行原因导致的影响优惠券领取、购买及其他无法享受优惠权益的情况，与农行无关，后续也不予补发优惠权益资格。
            </div>
            <div style="text-indent: 2em">
              （五）持卡人知悉互联网存在诸多不确定性。如出现不可抗力因素，或情势变更（如重大灾害事件、疫情管控、有权机关指令需停止或调整的、通讯线路及数据库系统故障、网络及黑客攻击、活动中存在违法作弊行为损害消费者权益等），中国农业银行陕西省分行有权延迟、修改、暂停或取消本活动并公告后生效。
            </div>
            <div style="text-indent: 2em">
              <span
                style="font-weight: 700; color: #ffffff">（六）持卡人参与本活动即视为理解并同意本活动规则。</span>在法律法规允许的范围内，中国农业银行陕西省分行保留对本次活动的变更权和提前终止权，并有权修订本活动条款及细则（包括但不限于活动对象、活动时间、参与资格及奖励方式）、调整或暂停或终止本活动，并于活动页面或其他相关渠道公告后生效，敬请留意。
              <div style="text-indent: 2em; font-weight: 700; color: #ffffff">
                特别提示：除夕日前15天以及春节期间（农历腊月十四至正月十五之前），商户有权暂停提供优惠美发服务，到店美发需付现金。春节后恢复正常接待服务。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <van-tabs v-model="active" :color="color" :ellipsis="false" @change="onTabChange">
        <van-tab v-for="service in services" :key="service.id" :title="service.title" />
      </van-tabs>
      <div class="navs">
        <!-- <div v-if="
          code !== 'NHHN' &&
          code !== 'NHJS' &&
          code !== 'SXNHCS' &&
          code !== 'SXNHWY'
        ">
          <div class="alert" v-if="showRemainCount">
            剩余数量：{{ services[active].remainCount }}
          </div>
        </div> -->
        <div class="alert" v-if="isLogin && services[active].isWhiteList && services[active].isShowAvailableCount">
          权益次数：{{ availableCount }}
        </div>
        <v-button block class="nav" v-if="services[active]" @click="buy(services[active], $event)">
          立即购买
        </v-button>
        <v-button block hairline plain class="nav" v-if="services[active]" @click="onShow">
          网点查询
        </v-button>
        <v-button block hairline plain class="nav" @click="goOrder">
          我的订单
        </v-button>
      </div>
      <div class="intro">
        <div>
          <van-divider :style="{ color: color }">权益使用流程</van-divider>
          <div style="margin: 0 15px 30px">
            <van-row>
              <van-col span="6" justify="center">
                <div class="circle-step">1</div>
                <div class="step-text">查看服务网点前往门店</div>
              </van-col>
              <van-col span="3">
                <div class="step-arrow">
                  <van-icon name="arrow" />
                </div>
              </van-col>
              <van-col span="6">
                <div class="circle-step">2</div>
                <div class="step-text">提前出示二维</div>
                <div class="step-text" style="margin-top: 0">码或电子劵码</div>
              </van-col>
              <van-col span="3">
                <div class="step-arrow">
                  <van-icon name="arrow" />
                </div>
              </van-col>
              <van-col span="6">
                <div class="circle-step">3</div>
                <div class="step-text">完成消费</div>
              </van-col>
            </van-row>
          </div>
        </div>
      </div>
      <div v-if="code !== 'SXNHWY'">
        <div v-if="services[active]" class="intro">
          <div v-for="(rule, index) in services[active].rules" :key="index">
            <van-divider :style="{ color: color }">{{ rule.name }}</van-divider>
            <div class="intro-content" v-html="rule.content.replace(/\n/g, '<br/>')"></div>
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="show" :actions="services.map((x) => ({ id: x.productId, name: x.title }))"
      cancel-text="取消" description="请选择产品" @select="onSelect" />
    <van-popup v-model="curtain" closeable close-icon="close" :style="{ width: '80%', backgroundColor: 'transparent' }">
      <img v-if="messageData.contentType == 2" :src="messageData.picture" class="curtain" />
    </van-popup>
    <van-popup v-model="serveShow" :style="{ width: '80%', backgroundColor: '#fff', borderRadius: '20px' }">
      <div class="serveBox">
        <div class="serveBox-tit">服务声明</div>
        <div class="serveBox-cont">
          <div class="cont-text">
            本服务由第三方南京蔚顺信息科技有限公司提供，相关服务和责任由南京蔚顺信息科技有限公司承担，服务商电话400-788-9395。银行仅为相关信息提供链接服务，不提供任何形式的担保和承诺。<br />
            为了您顺利参与活动体验服务，南京蔚顺信息科技有限公司需要获取并使用您的手机号码，参与活动时优惠券领取、使用等相关信息。我们将在您的授权范围内使用您的个人信息并尽责保护您的信息。
          </div>
          <div class="cont-check">
            <van-checkbox v-model="checked" shape="square" :checked-color="color" icon-size="16px">
              <span style="color: #59595a">同意后不再提醒</span>
            </van-checkbox>
          </div>
        </div>
        <div class="serveBox-btn">
          <div class="btn-left">
            <button :style="[{ borderColor: color }, { color: color }]" @click="onAgree(0)">
              不同意
            </button>
          </div>
          <div class="btn-right">
            <button :style="{ backgroundColor: color }" @click="onAgree(1)">
              同意
            </button>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  ActionSheet,
  Dialog,
  Divider,
  Popup,
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  Col,
  Row,
  Icon,
  Checkbox,
} from 'vant';
import { Button, NavBar, } from '../components';
import { mapState, mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'home',
  components: {
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Divider.name]: Divider,
    [NavBar.name]: NavBar,
    [Popup.name]: Popup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Checkbox.name]: Checkbox,
  },

  data() {
    return {
      active: 0,
      show: false,
      banners: [],
      availableCount: 0,
      curtain: false,
      messageData: '',
      noMessage: 0,
      serveShow: false,
      checked: false,
    };
  },
  computed: {
    ...mapState([
      'pictures',
      'bigPictures',
      'color',
      'code',
      'services',
      'title',
      'showRemainCount',
      'openid',
      'projectId',
      'custPhone',
      'customerPhone'
    ]),
    ...mapGetters(['isLogin']),
  },
  mounted() {
    document.title = this.title;
    if (
      this.bigPictures.length &&
      document.documentElement.clientWidth > 1000
    ) {
      this.banners = this.bigPictures;
    } else {
      this.banners = this.pictures;
    }
    this.getAvailableCount();
    // this.onCurtainShow();
    if (this.code === 'NHHN' || this.code === 'NHJS' || this.code === 'SXNHWY') {
      let ischeck = localStorage.getItem('isChecked') || '';
      console.log(ischeck);
      if (ischeck) {
        this.onGetMessage();
      } else {
        this.serveShow = true;
      }
    } else {
      this.onGetMessage();
    }

    if (this.code === 'NHJS') {
      history.pushState(
        history.length + 1,
        'message',
        window.location.href.split('#')[0] + '#' + new Date().getTime()
      );

      if (navigator.userAgent.indexOf('Android') != -1) {
        if (typeof tbsJs != 'undefined') {
          tbsJs.onReady('{useCachedApi : "true"}', function (e) { });
          window.onhashchange = function () {
            const num = window.history.length - 4;
            window.history.go(-num);
          };
        } else {
          window.onhashchange = function (event) {
            const num = window.history.length - 4;
            window.history.go(-num);
          };
        }
      } else {
        window.onhashchange = function () {
          const num = window.history.length - 4;
          window.history.go(-num);
        };
      }

      this.hideWxMenu();
      // this.$api.hideWxMenu()
    }

    // else if (this.code === 'SXNHCS') {
    //   var obj = {
    //     sign: this.getURLParams('sign'),
    //     openId: this.getURLParams('openId'),
    //     cityCode: this.getURLParams('cityCode'),
    //     name: this.getURLParams('name'),
    //   };
    //   console.log('马上跳转了');
    //   window.location.href =
    //     'http://bank.amywechat.com/NHSX/Authorize?sign='+obj.sign+'&openId='+obj.openId+'&cityCode='+obj.cityCode+'&name='+obj.name;
    // }
  },
  methods: {
    onAgree(val) {
      this.serveShow = false;
      if (this.checked && val) {
        localStorage.setItem('isChecked', 1);
      }
      this.onGetMessage();
    },
    getURLParams(key) {
      let href = location.href.split('?');
      if (href.length > 1) {
        let params = new URLSearchParams(href[1]);
        if (params.has(key)) {
          return params.get(key);
        }
      }
      return '';
    },
    hideWxMenu() {
      if (/micromessenger/i.test(navigator.userAgent)) {
        let href = encodeURIComponent(window.location.href);
        axios({
          method: 'GET',
          url: `/wechat/generateShareSign?url=${href}`,
        }).then(({ code, count, data, msg }) => {
          if (code) {
            return;
          }
          this.getWxConfig(data);

          wx.ready(function () {
            wx.hideAllNonBaseMenuItem();
          });
          wx.error(function (res) {
            wx.hideAllNonBaseMenuItem();
            console.info(res);
          });
        });
      }
    },
    getWxConfig(data) {
      window.wx.config({
        debug: false,
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        jsApiList: ['hideMenuItems', 'hideAllNonBaseMenuItem'],
      });
    },
    // onCurtainShow() {
    //   let count = localStorage.getItem('20210120') || 0;
    //   if (count) {
    //     count = Number(count);
    //   }

    //   if (count < 3 && Date.now() < new Date('2021-02-20T00:00:00').getTime()) {
    //     this.curtain = true;
    //     localStorage.setItem('20210120', count + 1);
    //   }
    // },
    onGetMessage() {
      if (this.$route.query.noMessage) {
        this.noMessage = 1;
        console.log(this.noMessage);
      }
      axios({
        url: `/api/v1/h5/Message/${this.code}/newMessage`,
        method: 'GET',
      }).then(({ code, data }) => {
        if (code === 0) {
          if (!this.noMessage) {
            if (data.isShow) {
              let count = localStorage.getItem(data.id);
              if (count) {
                if (count < data.limitCount) {
                  this.messageData = data;
                  if (this.messageData.contentType == 2) {
                    this.curtain = true;
                  } else {
                    Dialog.alert({
                      title: this.messageData.title,
                      message: this.messageData.content,
                    }).then(() => { });
                  }
                  let item = parseInt(count) + 1 - 0;
                  localStorage.setItem(data.id, item);
                }
              } else {
                this.messageData = data;
                if (this.messageData.contentType == 2) {
                  this.curtain = true;
                } else {
                  Dialog.alert({
                    title: this.messageData.title,
                    message: this.messageData.content,
                  }).then(() => { });
                }
                let item = 1;
                localStorage.setItem(data.id, item);
              }
            }
          }
        }
      });

      // let sessionId = localStorage.getItem(this.code) || '';
      // if (sessionId) {
      //   axios({
      //     url: `/api/v1/H5/message/${this.code}/systemMessage`,
      //     method: 'POST',
      //   }).then(({ code, data }) => {
      //     if (code === 0) {
      //       axios({
      //         url: `/api/v1/H5/message/${this.code}/message`,
      //         method: 'GET',
      //       }).then(({ code, data }) => {
      //         if (code === 0) {
      //           this.messageData = data;
      //           if (this.messageData.contentType == 2) {
      //             this.curtain = true;
      //           } else {
      //             Dialog.alert({
      //               title: this.messageData.title,
      //               message: this.messageData.content,
      //             }).then(() => {

      //             });
      //           }
      //         }
      //       });
      //     }
      //   });
      // } else {
      //         axios({
      //         url: `/api/v1/H5/message/${this.code}/systemNewMessage`,
      //         method: 'GET',
      //       }).then(({ code, data }) => {

      //         if (code === 0) {
      //           this.messageData = data;
      //           if (this.messageData.contentType == 2) {
      //             this.curtain = true;
      //           } else {
      //             Dialog.alert({
      //               title: this.messageData.title,
      //               message: this.messageData.content,
      //             }).then(() => {

      //             });
      //           }
      //         }
      //       });
      // }
    },
    onShow(event) {
      // if (this.code === 'NHHN') {
      //   sensors.quick(
      //     'trackAllHeatMap',
      //     event.target,
      //     {
      //       $title: '河南_城市专区_信用卡5元美发_首页', //与router中该页面配置的title保持一致
      //       firefly_prod_name: "5元美发",
      //       firefly_prod_type: "本地生活场景",
      //       firefly_prod_code: "103881606400100",
      //       pageprodinfo_1: "本地特色",
      //       firefly_click_area: '河南_城市专区_信用卡5元美发_功能按钮',
      //       firefly_click_section: '',
      //       firefly_click_name: '网点查询',
      //       firefly_click_code: '',
      //     },
      //     function () {
      //       console.log('callback');
      //     }
      //   );
      // }
      if (this.code === 'SXNHWY') {
        sensors.quick(
          'trackAllHeatMap',
          event.target,
          {
            $title: '陕西_本地优惠_乐享三秦', //与router中该页面配置的title保持一致
            firefly_click_area: '陕西_本地优惠_乐享三秦',
            firefly_click_section: '',
            firefly_click_name: '陕西_本地优惠_网点查询',
            firefly_click_code: '',
          },
          function () {
            console.log('callback');
          }
        );
      }
      if (this.services.length > 1) {
        this.show = true;
      } else {
        this.$router.push({
          name: 'network',
          query: { productId: this.services[this.active].productId },
        });
      }
    },
    onTabChange() {
      this.getAvailableCount();
    },
    onSelect(item) {
      this.show = false;
      this.$router.push({
        name: 'network',
        query: { productId: item.id },
      });
    },
    buy(item, e) {
      // if (this.code === 'NHHN') {
      //   sensors.quick(
      //     'trackAllHeatMap',
      //     e.target,
      //     {
      //       $title: '河南_城市专区_信用卡5元美发_首页', //与router中该页面配置的title保持一致
      //       firefly_prod_name: "5元美发",
      //       firefly_prod_type: "本地生活场景",
      //       firefly_prod_code: "103881606400100",
      //       pageprodinfo_1: "本地特色",
      //       firefly_click_area: '河南_城市专区_信用卡5元美发_功能按钮',
      //       firefly_click_section: '',
      //       firefly_click_name: '立即购买',
      //       firefly_click_code: '',
      //     },
      //     function () {
      //       console.log('callback');
      //     }
      //   );
      // }

      if (this.code === 'NHHN') {
        axios({
          url: `/api/v1/H5/account/${this.code}/queue`,
          method: 'POST',
          data: {},
        }).then(({ code, data, msg }) => {
          if (code != 0) {
            Dialog.alert({
              title: '购买提示',
              message: msg,
              confirmButtonText: '知道了',
              confirmButtonColor: this.color,
            });

            return;
          }

          if (item.remainCount > 0) {
            this.$router.push({
              name: 'pay',
              params: { id: item.id },
              query: { projectId: this.projectId },
            });
          } else {
            Dialog.alert({
              title: '购买提示',
              message: '本次购买数量已抢完，请继续保持关注。',
              confirmButtonText: '知道了',
              confirmButtonColor: this.color,
            });
          }
        });
      }
      else {
        if (this.code === 'SXNHWY') {
          sensors.quick(
            'trackAllHeatMap',
            e.target,
            {
              $title: '陕西_本地优惠_乐享三秦', //与router中该页面配置的title保持一致
              firefly_click_area: '陕西_本地优惠_乐享三秦',
              firefly_click_section: '',
              firefly_click_name: '陕西_本地优惠_订单支付',
              firefly_click_code: '',
            },
            function () {
              console.log('callback');
            }
          );
        }

        if (item.remainCount > 0) {
          this.$router.push({
            name: 'pay',
            params: { id: item.id },
            query: { projectId: this.projectId },
          });
        } else {
          Dialog.alert({
            title: '购买提示',
            message: '本次购买数量已抢完，请继续保持关注。',
            confirmButtonText: '知道了',
            confirmButtonColor: this.color,
          });
        }
      }


    },
    getAvailableCount() {
      const { isLogin, services, active } = this;

      if (!isLogin || !services[active].isWhiteList) {
        return;
      }
      axios
        .get(`/api/v1/h5/project/${services[active].id}/availableCount`)
        .then(({ count }) => {
          this.availableCount = count;
        });
    },
    goOrder(event) {
      // if (this.code === 'NHHN') {
      //   sensors.quick(
      //     'trackAllHeatMap',
      //     event.target,
      //     {
      //       $title: '河南_城市专区_信用卡5元美发_首页', //与router中该页面配置的title保持一致
      //       firefly_prod_name: "5元美发",
      //       firefly_prod_type: "本地生活场景",
      //       firefly_prod_code: "103881606400100",
      //       pageprodinfo_1: "本地特色",
      //       firefly_click_area: '河南_城市专区_信用卡5元美发_功能按钮',
      //       firefly_click_section: '',
      //       firefly_click_name: '我的订单',
      //       firefly_click_code: '',
      //     },
      //     function () {
      //       console.log('callback');
      //     }
      //   );
      // }
      if (this.code === 'SXNHWY') {
        sensors.quick(
          'trackAllHeatMap',
          event.target,
          {
            $title: '陕西_本地优惠_乐享三秦', //与router中该页面配置的title保持一致
            firefly_click_area: '陕西_本地优惠_乐享三秦',
            firefly_click_section: '',
            firefly_click_name: '陕西_本地优惠_我的订单',
            firefly_click_code: '',
          },
          function () {
            console.log('callback');
          }
        );
      }
      this.$router.push({ name: 'order' });
    },
    logout() {
      if (this.isLogin) {
        // localStorage.clear();
        localStorage.removeItem(this.code);
        window.location.reload();
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        from.name === 'order' ||
        from.name === 'pay' ||
        from.name === 'network' ||
        from.name === 'city'
      ) {
        vm.noMessage = 1;
      }
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      // console.log(vm);//当前组件的实例
    });
  },
};
</script>

<style scoped>
.swipe {
  height: 200px;
}

@media (min-width: 768px) {
  .swipe {
    height: auto;
  }
}

.swipe img {
  display: block;
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.navs {
  padding: 5px 15px;
  box-sizing: border-box;
}

.nav {
  box-sizing: border-box;
  margin: 15px 0;
  border-radius: 44px;
}

.nav::after {
  border-radius: 44px;
}

.intro {
  margin: 15px 0;
}

.intro-content {
  padding: 0 15px;
  font-size: 12px;
  line-height: 22px;
  color: #666;
}

.alert {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
  text-align: center;
}

.curtain {
  width: 100%;
  height: 100%;
}

.circle-step {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  background-color: #bfbfbf;
  color: #ffffff;
  font-size: 17px;
  margin: 0 auto;
}

.step-text {
  margin-top: 10px;
  text-align: center;
  color: #88888a;
  font-size: 12px;
}

.step-arrow {
  color: #bfbfbf;
  font-size: 17px;
  padding-top: 40px;
  text-align: center;
}

.serveBox {
  width: 100%;
  padding: 25px 20px;
}

.serveBox-tit {
  font-size: 18px;
  color: #000;
  text-align: center;
  font-weight: 500;
}

.serveBox-cont {
  margin-top: 20px;
}

.serveBox-cont .cont-text {
  font-size: 16px;
  line-height: 1.6em;
  color: #59595a;
}

.serveBox-cont .cont-check {
  margin-top: 12px;
  font-size: 16px;
  color: #59595a;
}

/* .cont-check .van-checkbox__label {
    font-size: 14px;
  
} */
.serveBox-btn {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.btn-left {
  flex: 1;
  text-align: center;
}

.btn-left button {
  width: 110px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid;
  font-size: 16px;
  border-radius: 40px;
  box-sizing: border-box;
}

.btn-right {
  flex: 1;
  text-align: center;
}

.btn-right button {
  width: 110px;
  height: 40px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 16px;
  border-radius: 40px;
  box-sizing: border-box;
}
</style>
