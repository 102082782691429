<template>
  <div class="container">
    <nav-bar title="订单支付" left-arrow @click-left="goBack" />
    <van-cell-group>
      <van-cell title="商品名称" :value="goods.title" />
      <van-cell title="价格" :value="`￥${goods.buyPrice || 0}`" />
    </van-cell-group>
    <div class="pay">
      <div class="pay-title">支付金额</div>
      <div class="pay-price">￥{{ goods.buyPrice }}</div>
      <div class="pay-origin">
        原价：￥
        <del>{{ goods.marketPrice }}</del>
      </div>
      <div class="pay-intro">{{ goods.remark }}</div>
    </div>
    <div style="margin-top: 15px">
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell
            :title="item.title"
            clickable
            @click="radio = item.id"
            v-for="(item, i) in goods.paymentTypes"
            :key="i"
          >
            <template #right-icon>
              <van-radio :checked-color="color" :name="item.id" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="pay-footer">
      <v-button block @click="onPay">立即支付</v-button>
      <!-- <div class="pay-tip">
        抢购成功后请尽快使用，有限期{{ goods.effectiveDay }}天，逾期自动退。
      </div> -->
    </div>
    <div v-html="content"></div>
  </div>
</template>

<script>
import axios from 'axios';
import { Cell, CellGroup, Icon, Toast, RadioGroup, Radio } from 'vant';
import { Button, NavBar } from '@/components';
import { mapState } from 'vuex';

export default {
  name: 'pay',
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  data() {
    return {
      adcode: '',
      goods: {},
      radio: 1,
      content: '',
    };
  },
  computed: mapState(['code', 'color', 'act']),
  mounted() {
    this.getProduct();
    this.getLocation();
    if (this.code === 'NHJS') {
      // this.$api.hideWxMenu()
      this.hideWxMenu();
    }
  },
  methods: {
    getProduct() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载数据中...',
      });
      const { id } = this.$route.params;
      axios({
        url: '/api/v1/h5/Product/',
        params: { id },
      }).then(({ data, code }) => {
        if (code) return Toast(msg);
        if (this.code === 'NHHN') {
          if (/bankabc/i.test(navigator.userAgent)) {
            let paymentTypes = [
              {
                id: 2,
                isDefault: true,
                title: '农行支付',
              },
            ];
            data.paymentTypes = paymentTypes;
            this.radio = 2;
          } else {
            let paymentTypes = [
              {
                id: 1,
                isDefault: true,
                title: '微信支付',
              },
            ];
            data.paymentTypes = paymentTypes;
            this.radio = 1;
          }
        }else if (this.code === 'SXNHCS') {
          if (/bankabc/i.test(navigator.userAgent)) {
            let paymentTypes = [
              {
                id: 2,
                isDefault: true,
                title: '农行支付',
              },
            ];
            data.paymentTypes = paymentTypes;
            this.radio = 2;
          } else {
            let paymentTypes = [
              {
                id: 1,
                isDefault: true,
                title: '微信支付',
              },
            ];
            data.paymentTypes = paymentTypes;
            this.radio = 1;
          }
        }  else {
          if (data.paymentTypes && data.paymentTypes.length) {
            data.paymentTypes.forEach((x) => {
              if (x.isDefault) {
                this.radio = x.id;
              }
            });
          }
        }
        this.goods = data;

        Toast.clear();
      });
    },
    getLocationAddress(lat, lng) {
      axios({
        url: `/account/Geocoder?location=${lat},${lng}`,
        method: 'GET',
      }).then(({ status, result }) => {
        if (status === 0) {
          this.adcode = result.ad_info.adcode;
        }
      });
    },
    getLocation() {
      // if (/micromessenger/i.test(navigator.userAgent)) {
      //   let href = decodeURIComponent(window.location.href);
      //   axios({
      //     method: 'GET',
      //     url: `/wechat/generateShareSign?url=${href}`,
      //   }).then(({ code, count, data, msg }) => {
      //     if (code) return Toast(msg);
      //     this.getWxConfig(data);
      //     let that = this;
      //     wx.ready(() => {
      //       wx.getLocation({
      //         type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      //         success: (res) => {
      //           that.location.lat = res.latitude; // 纬度，浮点数，范围为90 ~ -90
      //           that.location.lng = res.longitude; // 经度，浮点数，范围为180 ~ -180。
      //           that.getLocationAddress(res.latitude, res.longitude);
      //         },
      //         fail: (err) => {
      //           Dialog.alert({
      //             title: '提示',
      //             message: '未能定位到你的位置，请开启定位后重试',
      //           });
      //         },
      //       });
      //     });
      //   });
      // } else {
      const geolocation = new qq.maps.Geolocation(
        'NFMBZ-6RUWS-LJDOP-62EZB-PAYKO-XOFA4',
        'yunzhi'
      );
      geolocation.getLocation(
        ({ adcode }) => {
          this.adcode = adcode;
        },
        () => {
          Dialog.alert({
            title: '提示',
            message: '未能定位到你的位置，请开启定位后重试',
          });
        },
        { timeout: 3000 }
      );
      // }
    },
    getWxConfig(data) {
      window.wx.config({
        debug: false,
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        jsApiList: ['chooseWXPay', 'getLocation','hideMenuItems'],
      });
    },
    onPay() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '提交中...',
      });
      let act = localStorage.getItem('act');
      axios({
        method: 'POST',
        url: '/api/v1/h5/pay',
        data: {
          id: this.goods.id,
          regionId: this.adcode,
          paymentType: this.radio,
          actCode: act,
        },
      }).then(({ code, count, data, msg }) => {
        if (code) return Toast(msg);
        if (count === 1) {
          this.wxPay(data);
        } else if (count === 2 || count === 3) {
          Toast.clear();

          if(this.code === 'NHHN'){
            let result = data;

            result = result.replace('https://www.abchina.com/luascript/mobilePayLua/{"return":', '');

            result = result.substr(0, result.length - 1); 

            let obj = JSON.parse(result);

            window.AlipayJSBridge && AlipayJSBridge.call('startApp',{
              appId:'30603024', // 固定值，谁调支付都是这个值
              param:{
                type:"3", // 3： 掌银内H5页面
                tokenId:obj.tokenID, //商户传过来的tokenId
                paySystem:"", //商户传过来的paySystem, 没有则传空字符串
                payType:"", //商户传过来的payType，没有则传'1111'
                webviewURL:obj.backURL, //商户传过来的回跳地址
                remark:"", //扩展备用
                startMultApp: 'YES', //可选。需要在一个H5内多次调起支付时须加
                showProgress:'NO',
                backBehavior:'back'
              },
            }, function(result){});
          }
          else{
            window.location.href = data;
          }
        } else if (count === 5) {
          window.location.href = data || '';
        }
      });
    },
    wxPay(data) {
      this.getWxConfig(data);
      window.wx.ready(() => {
        wx.chooseWXPay({
          timestamp: data.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。
          nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
          package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: 'MD5', // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: data.paysign, // 支付签名
          success: () => {
            this.$router.push({
              name: 'result',
              params: { id: data.orderId },
            });
          },
          complete: () => Toast.clear(),
        });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    hideWxMenu() {
      if (/micromessenger/i.test(navigator.userAgent)) {
        let href = encodeURIComponent(window.location.href);
        axios({
          method: 'GET',
          url: `/wechat/generateShareSign?url=${href}`,
        }).then(({ code, count, data, msg }) => {
          if (code) {
            return;
          }
          this.getWxConfig(data);
          wx.ready(() => {
            wx.hideAllNonBaseMenuItem();

            // wx.hideMenuItems({
            //   menuList: [
            //     'menuItem:share:timeline',
            //     'menuItem:copyUrl',
            //     'menuItem:share:appMessage',
            //     'menuItem:share:qq',
            //     'menuItem:share:weiboApp',
            //     'menuItem:favorite',
            //     'menuItem:share:facebook',
            //     'menuItem:share:QZone',
            //     'menuItem:editTag',
            //     'menuItem:delete',
            //     'menuItem:copyUrl',
            //     'menuItem:originPage',
            //     'menuItem:readMode',
            //     'menuItem:openWithQQBrowser',
            //     'menuItem:openWithSafari',
            //     'menuItem:share:email',
            //     'menuItem:share:brand',
            //   ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
            // });
          });
          wx.error(function (res) {
            wx.hideAllNonBaseMenuItem();
            console.info(res);
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
  background-color: #fafafa;
}
.pay {
  margin-top: 12px;
  padding: 24px 0 32px;
  background-color: #fff;
  text-align: center;
  color: #323233;
}
.pay-title {
  font-size: 13px;
}
.pay-price {
  margin: 16px 0 12px;
  font-size: 40px;
  font-weight: bold;
  color: #ee0a24;
}
.pay-intro {
  margin-top: 8px;
  font-size: 13px;
  color: #f66e1a;
}
.pay-style {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
.pay-footer {
  padding: 24px 16px;
}
.pay-tip {
  margin-top: 16px;
  font-size: 13px;
  color: #8a8a8a;
  text-align: center;
}
</style>
