<template>
  <div id="app">
    <div v-if="loading" class="spalsh">
      <van-loading size="24px" type="spinner" v-if="loading" />
    </div>
    <div v-else>
      <keep-alive>
        <div v-if="error">{{ message }}</div>
        <router-view v-else></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { Loading } from 'vant';

export default {
  components: {
    [Loading.name]: Loading,
  },
  data() {
    return {
      error: false,
      loading: true,
      message: '参数错误',
    };
  },
  created: function () {
    let { params } = this.$route;
    let code = params.code || location.pathname.split('/')[1];

    if (code) {
      this.$store
        .dispatch('getProject', params.code || code)
        .then(({ code, data, msg }) => {
          if (code) {
            this.error = true;
            this.message = msg;
          } else {
            document.title = data.title;
          }
          this.loading = false;
        });

      this.abc(code);

    } else {
      this.error = true;
      this.loading = false;
    }
  },
  methods: {
    abc: function (code) {
      if (code !== 'NHHN') return;

      if (window.AlipayJSBridge) {
        AlipayJSBridge.call('setOptionMenu', {
          menus: [
            {
              icon: 'PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNjE0NDQwNTAyOTAzIiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjE2NjgiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCI+PGRlZnM+PHN0eWxlIHR5cGU9InRleHQvY3NzIj48L3N0eWxlPjwvZGVmcz48cGF0aCBkPSJNMjc4LjYxMzMzMyAyMzIuNDY5MzMzbDIzMy42IDIzMy42IDIzMy4zODY2NjctMjMzLjM2NTMzM2EyMS4zMzMzMzMgMjEuMzMzMzMzIDAgMCAxIDMwLjE2NTMzMyAwbDE1LjA4MjY2NyAxNS4wODI2NjdhMjEuMzMzMzMzIDIxLjMzMzMzMyAwIDAgMSAwIDMwLjE4NjY2Nkw1NTcuNDgyNjY3IDUxMS4zMzg2NjdsMjM0LjAyNjY2NiAyMzQuMDI2NjY2YTIxLjMzMzMzMyAyMS4zMzMzMzMgMCAwIDEgMCAzMC4xNjUzMzRsLTE1LjA4MjY2NiAxNS4wODI2NjZhMjEuMzMzMzMzIDIxLjMzMzMzMyAwIDAgMS0zMC4xNjUzMzQgMGwtMjM0LjAyNjY2Ni0yMzQuMDI2NjY2TDI3Ny45NzMzMzMgNzkwLjgyNjY2N2EyMS4zMzMzMzMgMjEuMzMzMzMzIDAgMCAxLTMwLjE4NjY2NiAwbC0xNS4wODI2NjctMTUuMDgyNjY3YTIxLjMzMzMzMyAyMS4zMzMzMzMgMCAwIDEgMC0zMC4xNjUzMzNMNDY2Ljk4NjY2NyA1MTEuMzM4NjY3bC0yMzMuNi0yMzMuNmEyMS4zMzMzMzMgMjEuMzMzMzMzIDAgMCAxIDAtMzAuMTY1MzM0bDE1LjEwNC0xNS4xMDRhMjEuMzMzMzMzIDIxLjMzMzMzMyAwIDAgMSAzMC4xNjUzMzMgMHoiIGZpbGw9IiMwMDAwMDAiIHAtaWQ9IjE2NjkiPjwvcGF0aD48L3N2Zz4=', //“关闭”图标 Base64。40x40，周边不留白
            },
          ],
          override: true, // 是否覆盖默认的 optionMenu，统一传 true
        });
        AlipayJSBridge.call('showOptionMenu'); // 必须调用一次以刷新界面

        // 点击事件
        document.addEventListener(
          'optionMenu',
          function (e) {
            if (e.data.index == 0) {
              // 用户点击了第一个图标。setOptionMenu 方法中，设置了第一个图标为“关闭”
              AlipayJSBridge.call('abcExitWebAndBackToHome');
            }
          },
          false
        );
      }
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}
</style>

<style>
.spalsh {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.van-dialog__message {
  overflow-y: unset !important;
}
</style>
