<template>
  <div class="container">
    <nav-bar title="订单二维码" left-arrow @click-left="goBack" />
    <div v-if="order.status === 2">
       <div class="qrcode" >
      <div
        v-if="order.qrCodeExpiresTime"
        style="display: flex; justify-content: center"
      >
        <div style="position: relative; width: 200px; height: 200px">
          <van-image width="200" height="200" :src="order.qrCode" />
          <div
            v-if="!qrcodeData.isShow"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 200px;
              height: 200px;
              position: absolute;
              top: 0;
              left: 0;
              background-color: rgba(255, 255, 255, 0.9);
            "
          >
            <div
              style="width: 80px; height: 60px; text-align: center"
              @click="onRefresh()"
            >
              <img
                src="../../assets/refresh.png"
                style="width: 40px; height: 40px"
              />
              <div
                style="
                  text-align: center;
                  color: #323233;
                  font-size: 14px;
                  font-weight: 500;
                  margin-top: 10px;
                "
              >
                点击刷新
              </div>
            </div>
          </div>
          <div
            style="
              width: 100%;
              height: 30px;
              text-align: center;
              color: #ff976a;
              font-size: 14px;
              margin-top: 10px;
              position: absolute;
              bottom: -30px;
              left: 0;
            "
          >
            {{ qrcodeData.codeText }}
          </div>
        </div>
      </div>
      <van-image v-else width="200" height="200" :src="order.qrCode" />
      
    </div>
    <div style="text-align: center;margin-top:15px;font-size:14px;" @click="onRefresh()" :style="{ color: color }">点击刷新二维码</div>
    </div>
   
    
    <div class="status" v-else>
      <van-icon
        name="checked"
        color="#07c160"
        size="64"
        v-if="order.status === 3 || order.status === 5"
      />
      <van-icon
        name="underway"
        color="#10aeff"
        size="64"
        v-if="order.status === 4"
      />
      <van-icon
        name="warning"
        color="#10aeff"
        size="64"
        v-if="order.status === 6"
      />
      <p>{{ order.statusText }}</p>
    </div>
    <div style="margin-top: 30px">
      <div class="meta" v-if="order.status === 2 && order.qrCodeExpiresTime">
        核销期至：{{ order.qrCodeExpiresTime }}
      </div>
      <div class="meta" v-if="order.actCodeText">
        活动类型：{{ order.actCodeText }}
      </div>
      <div class="meta">商品名称：{{ order.productTitle }}</div>
      <div class="meta" v-if="order.status === 2 && !order.qrCodeExpiresTime">
        订单串码：<strong>{{ order.hxCode }}</strong>
      </div>
      <div class="meta">下单时间：{{ order.paymentTime }}</div>
      <div class="meta" v-if="order.status === 2">
        有效期至：{{ order.expireTime }}
      </div>
      <!-- <div class="notice" v-if="order.status === 2">
        {{
          code === 'NHHN'
            ? `*自付款成功后至 ${order.expireTime} 有效，请尽快使用`
            : `*自付款成功后${order.expireDays}日内有效，请尽快使用`
        }}
      </div> -->
      <div class="meta" v-if="order.status === 3">
        消费网点：{{ order.networkTitle }}
      </div>
      <div class="meta" v-if="order.status === 3">
        消费时间：{{ order.hexiaoTime }}
      </div>
      <div class="meta" v-if="order.status > 3">
        退款时间：{{ order.refundTime }}
      </div>
      <div class="meta" v-if="order.status > 3">
        退款金额：{{ order.refundPrice.toFixed(2) }}元
      </div>
      <div class="meta" v-if="order.status > 3">
        退款说明：{{ order.remark }}
      </div>
    </div>

    <div class="footer">
      <v-button block @click="goNetwork"> 查看服务网点 </v-button>
      <v-button block plain class="button" @click="goHome">返回首页</v-button>
    </div>
    <div style="margin-top: 15px; padding: 0 24px">
      <div class="instruction-title">使用说明</div>
      <div class="instruction-text" style="margin-top: 15px">
        1.到店提前出示电子订单二维码
      </div>
      <div class="instruction-text">2.请告知商家本卡券由赟质提供</div>
      <div class="instruction-text">
        3.如有其他问题请联系赟质<a
          :style="{ color: color }"
          :href="'tel:' + 4007889395"
          >400-788-9395</a
        >
      </div>
      <div class="instruction-text">
        4.本券只限{{ order.productTitle || '' }}服务
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Icon, Image } from 'vant';
import { Button, NavBar } from '@/components';
import { mapState } from 'vuex';

export default {
  components: {
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Image.name]: Image,
  },
  data() {
    return {
      timer:'',
      order: {},
      qrcodeData: {
        isShow: true,
        surTime: '',
        codeText: '',
      },
    };
  },
  computed: mapState(['code', 'nhjsOpenid', 'custPhone', 'act', 'color']),
  created: function () {
    this.fetchData();
  },
  activated(){
    this.fetchData();
  },
  mounted() {
    if (this.code === 'NHJS') {
      // this.$api.hideWxMenu()
      this.hideWxMenu();
    }
  },
  methods: {
    fetchData() {
      axios({
        url: '/api/v1/h5/Orders',
        params: {
          id: this.$route.params.id,
        },
      }).then(({ data }) => {
        this.order = data;

        if (data.qrCOdeExpressSecond) {
          if (data.qrCOdeExpressSecond>0) {
            this.qrcodeData.isShow = true;
            let surTime = parseInt(data.qrCOdeExpressSecond);
            this.qrcodeData.codeText = '二维码' + surTime + '秒后失效';
             clearInterval(this.timer);
            this.setIntervalTime(surTime);
          } else {
            this.qrcodeData.isShow = false;
          }
        }
      });
    },
    setIntervalTime(n) {
      this.timer = setInterval(() => {
        if (n === 1) {
          this.qrcodeData.isShow = false;
          this.qrcodeData.codeText = '二维码已失效请点击图片刷新';
          clearInterval(this.timer);
        } else {
          n--;
          if (n < 10 && n > 1) {
            n = '0' + n;
          }
          this.qrcodeData.codeText = '二维码' + n + '秒后失效';
        }
      }, 1000);
    },
    onRefresh() {
      this.fetchData();
    },
    goBack() {
      this.$router.go(-1);
    },
    goNetwork() {
      this.$router.push({
        name: 'network',
        query: { productId: this.order.productId },
      });
    },
    goHome() {
      if (this.code === 'NHJS') {
        let custPhone = localStorage.getItem('custPhone');
        let nhjsOpenid = localStorage.getItem('nhjsOpenid');
        let act = localStorage.getItem('act');
        this.$router.replace({
          name: 'home',
          query: {
            custPhone: custPhone,
            openid: nhjsOpenid,
            act: act,
            noMessage: 1,
          },
        });
        window.location.reload();
      } 
      else if (this.code === 'NHHN'){
        let customerId = localStorage.getItem('customerId');

        window.location.href = 'https://h5.finequality1.com/NHHN?customerId=' + customerId;
      }
      else {
        this.$router.replace({ name: 'home', query: { noMessage: 1 } });
        window.location.reload();
      }
    },
    hideWxMenu() {
      if (/micromessenger/i.test(navigator.userAgent)) {
        let href = encodeURIComponent(window.location.href);
        axios({
          method: 'GET',
          url: `/wechat/generateShareSign?url=${href}`,
        }).then(({ code, count, data, msg }) => {
          if (code) {
            return;
          }
          this.getWxConfig(data);
          wx.ready(() => {
            wx.hideAllNonBaseMenuItem();

            // wx.hideMenuItems({
            //   menuList: [
            //     'menuItem:share:timeline',
            //     'menuItem:copyUrl',
            //     'menuItem:share:appMessage',
            //     'menuItem:share:qq',
            //     'menuItem:share:weiboApp',
            //     'menuItem:favorite',
            //     'menuItem:share:facebook',
            //     'menuItem:share:QZone',
            //     'menuItem:editTag',
            //     'menuItem:delete',
            //     'menuItem:copyUrl',
            //     'menuItem:originPage',
            //     'menuItem:readMode',
            //     'menuItem:openWithQQBrowser',
            //     'menuItem:openWithSafari',
            //     'menuItem:share:email',
            //     'menuItem:share:brand',
            //   ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
            // });
          });
          wx.error(function (res) {
            wx.hideAllNonBaseMenuItem();
            console.info(res);
          });
        });
      }
    },
    getWxConfig(data) {
      window.wx.config({
        debug: false,
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        jsApiList: ['hideMenuItems','hideAllNonBaseMenuItem'],
      });
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
}
.qrcode {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.status {
  padding: 32px 0;
  text-align: center;
}
.meta {
  display: flex;
  padding: 0 24px 8px;
  font-size: 14px;
  color: #323233;
  line-height: 1.75;
}
.notice {
  margin-top: 20px;
  font-size: 14px;
  color: #ff976a;
  text-align: center;
}
.footer {
  padding: 24px;
}
.button {
  margin-top: 16px;
}
.instruction-title {
  color: #323233;
  font-size: 14px;
  font-weight: 600;
}
.instruction-text {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
