<template>
  <div class="container">
    <nav-bar title="投诉" left-arrow @click-left="goBack" />
    <van-cell-group title>
      <van-cell title="商品名称" :value="data.productTitle" />
      <van-cell title="价格" :value="`￥${data.paymentPrice}`" />
    </van-cell-group>
    <van-cell-group title style="margin-top: 10px">
      <van-cell title="投诉图片上传" :value="fileList.length + '/4'" />
    </van-cell-group>
    <div
      style="
        padding: 10px 20px;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
      "
    >
      <img
        v-for="(item, i) in fileList"
        :key="i"
        width="80"
        height="80"
        style="margin: 0 8px 8px 0"
        :src="item.url"
      />
      <van-uploader
        v-if="fileList.length < 4"
        :after-read="afterRead"
        multiple
        :accept="'image/*'"
        :max-count="4"
      >
      </van-uploader>
    </div>
    <van-cell-group title style="margin-top: 10px">
      <van-cell title="投诉内容" />
    </van-cell-group>
    <van-cell-group>
      <van-field
        v-model="content"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入投诉内容"
      />
    </van-cell-group>
    <div class="rate-footer">
      <v-button block @click="onSubmit">提交</v-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Cell, CellGroup, Field, Rate, Tag, Toast, Uploader } from 'vant';
import { Button, NavBar } from '@/components';
import { mapState } from 'vuex';

export default {
  name: 'orderComplain',
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [NavBar.name]: NavBar,
    [Rate.name]: Rate,
    [Tag.name]: Tag,
    [Toast.name]: Toast,
    [Uploader.name]: Uploader,
  },
  data() {
    return {
      data: {},
      star: 5,
      content: '',
      fileList: [],
    };
  },
  computed: mapState(['code']),
  created() {
    axios({
      url: '/api/v1/h5/Orders',
      params: {
        id: this.$route.params.id,
      },
    }).then(({ data }) => {
      this.data = data;
    });
  },
  mounted() {
    if (this.code === 'NHJS') {
      // this.$api.hideWxMenu()
      this.hideWxMenu();
    }
  },
  methods: {
    afterRead(file) {
      if (file.length > 0) {
        file.forEach((item) => {
          let fd = new FormData();
          fd.append('upImgs', item.file);
          axios
            .post(`/api/v1/h5/component/sid/UploadPicture`, fd, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(({ code, msg, data }) => {
              if (code) {
                Toast(msg);
              } else {
                this.fileList.push(data[0]);
                // Toast.success('上传成功');
              }
            });
        });
      }
    },
    onSubmit() {
      let pictures = '';
      let arry = [];
      if (this.fileList.length > 0) {
        this.fileList.forEach((item) => {
          arry.push(item.url);
        });
      }
      if (arry.length > 0) {
        pictures = arry.join(',');
      }
      let data = {
        content: this.content,
        pictures: pictures || '',
      };
      Toast.loading({ message: '加载中...', forbidClick: true });
      axios({
        url: `/api/v1/H5/Orders/${data.id}/complaints`,
        method: 'POST',
        data: data,
      }).then(({ code, msg }) => {
        if (code) {
          Toast(msg);
        } else {
          Toast.success('提交成功');
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    hideWxMenu() {
      if (/micromessenger/i.test(navigator.userAgent)) {
        let href = encodeURIComponent(window.location.href);
        axios({
          method: 'GET',
          url: `/wechat/generateShareSign?url=${href}`,
        }).then(({ code, count, data, msg }) => {
          if (code) {
            return;
          }
          this.getWxConfig(data);
          wx.ready(() => {
            wx.hideAllNonBaseMenuItem();

            // wx.hideMenuItems({
            //   menuList: [
            //     'menuItem:share:timeline',
            //     'menuItem:copyUrl',
            //     'menuItem:share:appMessage',
            //     'menuItem:share:qq',
            //     'menuItem:share:weiboApp',
            //     'menuItem:favorite',
            //     'menuItem:share:facebook',
            //     'menuItem:share:QZone',
            //     'menuItem:editTag',
            //     'menuItem:delete',
            //     'menuItem:copyUrl',
            //     'menuItem:originPage',
            //     'menuItem:readMode',
            //     'menuItem:openWithQQBrowser',
            //     'menuItem:openWithSafari',
            //     'menuItem:share:email',
            //     'menuItem:share:brand',
            //   ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
            // });
          });
          wx.error(function (res) {
            wx.hideAllNonBaseMenuItem();
            console.info(res);
          });
        });
      }
    },
    getWxConfig(data) {
      window.wx.config({
        debug: false,
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        jsApiList: ['hideMenuItems','hideAllNonBaseMenuItem'],
      });
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
  background-color: #fafafa;
}

.rating-star {
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding: 24px 16px;
  background-color: #fff;
}

.rating-label {
  font-size: 14px;
}

.rating-tag {
  background-color: #fff;
  padding: 0 16px 16px;
}

.rate-footer {
  margin: 24px 16px;
}

.van-tag {
  margin-right: 10px;
  padding: 0.4em 1em;
}
</style>
