<template>
  <van-nav-bar
    v-if="show"
    v-bind="$attrs"
    v-on="$listeners"
    :border="false"
    :style="{ backgroundColor: color, color: 'white !important' }"
  />
</template>

<script>
import { NavBar } from 'vant';
import { mapState } from 'vuex';

export default {
  name: 'NavBar',
  data() {
    return {
      show: true,
    };
  },
  components: {
    [NavBar.name]: NavBar,
  },
  computed: mapState(['code', 'color']),
  created() {
    if (this.code === 'NHHN') {
      this.show = false;
    }
  },
};
</script>

<style>
.van-nav-bar__title {
  color: white;
}
.van-nav-bar .van-icon {
  color: white;
}
.van-nav-bar__arrow {
  font-size: 20px;
}
.van-nav-bar__text {
  color: white;
}
</style>
