import axios from 'axios';
function getURLParams(key) {
  let href = location.href.split('?');
  if (href.length > 1) {
    let params = new URLSearchParams(href[1]);
    if (params.has(key)) {
      return params.get(key);
    }
  }
  return '';
}

function isWeixinBrowser() {
  return /micromessenger/i.test(navigator.userAgent);
}
const hideWxMenu = function () {
  // console.log('开始执行隐藏按钮了');
  if (/micromessenger/i.test(navigator.userAgent)) {
    let href = decodeURIComponent(window.location.href);
    axios({
      method: 'GET',
      url: `/wechat/generateShareSign?url=${href}`,
    }).then(({ code, count, data, msg }) => {
      if (code) {
        return;
      }
      window.wx.config({
        debug: false,
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        jsApiList: ['hideMenuItems', 'hideAllNonBaseMenuItem'],
      });
      wx.ready(() => {
        wx.hideAllNonBaseMenuItem();
        // wx.hideMenuItems({
        //   menuList: [
        //     'menuItem:share:timeline',
        //     'menuItem:copyUrl',
        //     'menuItem:share:appMessage',
        //     'menuItem:share:qq',
        //     'menuItem:share:weiboApp',
        //     'menuItem:favorite',
        //     'menuItem:share:facebook',
        //     'menuItem:share:QZone',
        //     'menuItem:editTag',
        //     'menuItem:delete',
        //     'menuItem:copyUrl',
        //     'menuItem:originPage',
        //     'menuItem:readMode',
        //     'menuItem:openWithQQBrowser',
        //     'menuItem:openWithSafari',
        //     'menuItem:share:email',
        //     'menuItem:share:brand',
        //   ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
        // });
      });
      wx.error(function (res) {
        wx.hideAllNonBaseMenuItem();
        console.info(res);
      });
    });
  }
}


export default { getURLParams, isWeixinBrowser, hideWxMenu };
