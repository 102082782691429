<template>
  <div class="container">
    <div v-if="code !== 'NHJS'">
      <van-icon name="cross" size="24" @click="goBack" />
      <div class="content">
        <h2 class="welcome">欢迎登录</h2>
        <div class="form">
          <van-field
            v-model="bankcard"
            v-if="setting.isBankcard"
            placeholder="银行卡号"
          />
          <van-field v-model="account" placeholder="手机号" />
          <van-field v-model="sms" center clearable placeholder="短信验证码">
            <v-button
              slot="button"
              size="small"
              class="button-sms"
              :disabled="!canSendSms"
              @click="onSend"
            >
              {{ sending ? `${countdown} 秒` : sendText }}
            </v-button>
          </van-field>
        </div>
        <v-button block class="button" :disabled="disabled" @click="onLogin">
          登 录
        </v-button>
        <div class="help">
          <a @click="onHelp">遇到问题</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { Button } from '../components';
import { Dialog, Field, Icon, Toast } from 'vant';

export default {
  name: 'login',
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
  },
  data() {
    return {
      account: '',
      bankcard: '',
      countdown: 59,
      sms: '',
      sending: false,
      sendText: '发送验证码',
    };
  },
  computed: {
    isPhoneNumber: function () {
      return /^1\d{10}$/.test(this.account);
    },
    canSendSms: function () {
      return this.isPhoneNumber && !this.sending;
    },
    isSms: function () {
      return /^\d{4}$/.test(this.sms);
    },
    disabled: function () {
      return (this.isPhoneNumber && this.isSms) === false;
    },
    ...mapState(['projectId', 'setting', 'openid', 'code', 'phone', 'custId']),
  },
  created() {
    let bankcard = localStorage.setItem('BANKCARDNUMBER', this.bankcard);
    if (bankcard) {
      this.bankcard = bankcard;
    }
    if (this.code === 'NHJS') {
      this.onWarning();
    } else if (this.code === 'NHHN') {
      this.account = this.phone;
    }
  },
  methods: {
    onHelp: function () {
      Dialog.alert({
        title: '无法登录',
        message:
          '如果您无法收到短信，您可以先核实如下： 1、手机号码是否正确  2、手机是否设置短信拦截 ',
        confirmButtonText: '知道了',
      });
    },
    onWarning: function () {
      Dialog.alert({
        title: '无法登录',
        message: '抱歉，该项目只能在微信浏览器进行登录，请切换到微信浏览器',
        confirmButtonText: '知道了',
      });
    },
    onSend: function () {
      this.sending = true;
      let timer = setInterval(() => {
        if (this.countdown <= 1) {
          clearInterval(timer);
          this.countdown = 59;
          this.sending = false;
          this.sendText = '重新发送';
        } else {
          this.countdown--;
        }
      }, 1000);
      axios({
        url: '/api/v1/h5/Account/1/VerificationCode',
        method: 'POST',
        data: { account: this.account },
      }).then(({ code, msg }) => {
        if (code) Toast(msg);
      });
    },
    onLogin: function () {
      Toast.loading({
        message: '登录中...',
        forbidClick: true,
      });

      axios({
        url: `/api/v1/h5/Account/${this.projectId}/Login`,
        method: 'POST',
        data: {
          account: this.account,
          custId: this.custId,
          code: this.sms,
          bankcard: this.bankcard,
          openId: this.openid,
        },
      }).then(({ code, data, msg }) => {
        try{
          Toast.clear();
          if (code) {
            Toast(msg);
          } else {
            this.$store.commit('setToken', { token: data });

            localStorage.setItem('BANKCARDNUMBER', this.bankcard);

            window.location.href = 'https://h5.finequality1.com/'+this.code;
          }
        }
        catch{
          alert('异常了')
        }
      });
    },
    goBack: function () {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>

<style scoped>
.container {
  padding: 15px;
}
.content {
  padding: 30px 15px;
}
.welcome {
  font-weight: lighter;
  padding: 32px 0 24px;
}
.form {
  margin: 0 -16px 30px;
}
.button {
  border-radius: 8px;
}
.button-sms {
  border-radius: 4px;
}
.help {
  margin-top: 15px;
  font-size: 12px;
  text-align: center;
}
</style>
