var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('nav-bar',{attrs:{"title":"选择城市","left-arrow":""},on:{"click-left":_vm.goBack}}),(_vm.location)?_c('van-cell',{attrs:{"icon":"location-o","title":_vm.location.name},on:{"click":function($event){return _vm.onSelect(_vm.location.code, _vm.location.name)}}}):_vm._e(),_vm._l((Object.entries(_vm.regions.province_list)),function(ref){
var provinceCode = ref[0];
var province = ref[1];
return _c('div',{key:provinceCode},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(province))]),_vm._l((Object.entries(_vm.regions.city_list).filter(function (x) { return x[0].startsWith(provinceCode.slice(0, 2)); }
      )),function(ref){
      var code = ref[0];
      var city = ref[1];
return _c('van-cell',{key:code,attrs:{"title":city},on:{"click":function($event){return _vm.onSelect(code, city)}}})})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }