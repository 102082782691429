<template>
  <div class="container">
    <nav-bar title="退款申请" left-arrow @click-left="onBack" />
    <van-cell-group title>
      <van-cell title="商品名称" :value="data.title" />
      <van-cell title="价格" :value="`￥${data.price}`" />
    </van-cell-group>

    <van-cell-group title="退款方式">
      <van-cell title="原路返回" value="1-3个工作日退回到原支付账户" />
    </van-cell-group>

    <van-radio-group v-model="type">
      <van-cell-group title="退款原因">
        <van-cell
          clickable
          v-for="(value, index) in reason"
          :key="index"
          :title="value"
          @click="type = index"
        >
          <van-radio slot="right-icon" :checked-color="color" :name="index" />
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <div class="refund-tip">退款申请一经提交不可撤销</div>

    <div class="refund-footer">
      <v-button block @click="onSubmit">申请退款</v-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Cell, CellGroup, RadioGroup, Radio, Toast } from 'vant';
import { Button, NavBar } from '@/components';
import { mapState } from 'vuex';

export default {
  name: 'refund',
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Toast.name]: Toast,
  },
  data() {
    return {
      type: 0,
      data: {},
      reason: [
        '联系商家，约不到时间',
        '计划有变，不想要了',
        '到店无法使用',
        '其他原因',
      ],
    };
  },
  computed: mapState(['color', 'code']),
  created() {
    axios({
      url: '/api/v1/shopping/orders',
      method: 'GET',
      params: {
        id: this.$route.params.id,
      },
    }).then(({ data }) => {
      this.data = data;
    });
  },
  mounted() {
    if (this.code === 'NHJS') {
      // this.$api.hideWxMenu()
      this.hideWxMenu();
    }
  },
  methods: {
    onSubmit() {
      const { type, reason, data } = this;
      Toast.loading({ message: '加载中...', forbidClick: true });
      axios({
        url: `/api/v1/shopping/orders/${data.id}/refund`,
        method: 'POST',
        data: { remark: reason[type] },
      }).then(({ code, msg }) => {
        if (code) {
          Toast(msg);
        } else {
          Toast.success({
            message: msg,
            forbidClick: true,
            onClose: () => {
              this.$router.go(-1);
            },
          });
        }
      });
    },
    onBack() {
      this.$router.go(-1);
    },
    hideWxMenu() {
      if (/micromessenger/i.test(navigator.userAgent)) {
        let href = encodeURIComponent(window.location.href);
        axios({
          method: 'GET',
          url: `/wechat/generateShareSign?url=${href}`,
        }).then(({ code, count, data, msg }) => {
          if (code) {
            return;
          }
          this.getWxConfig(data);
          wx.ready(() => {
            wx.hideAllNonBaseMenuItem();

            // wx.hideMenuItems({
            //   menuList: [
            //     'menuItem:share:timeline',
            //     'menuItem:copyUrl',
            //     'menuItem:share:appMessage',
            //     'menuItem:share:qq',
            //     'menuItem:share:weiboApp',
            //     'menuItem:favorite',
            //     'menuItem:share:facebook',
            //     'menuItem:share:QZone',
            //     'menuItem:editTag',
            //     'menuItem:delete',
            //     'menuItem:copyUrl',
            //     'menuItem:originPage',
            //     'menuItem:readMode',
            //     'menuItem:openWithQQBrowser',
            //     'menuItem:openWithSafari',
            //     'menuItem:share:email',
            //     'menuItem:share:brand',
            //   ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
            // });
          });
          wx.error(function (res) {
            wx.hideAllNonBaseMenuItem();
            console.info(res);
          });
        });
      }
    },
    getWxConfig(data) {
      window.wx.config({
        debug: false,
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        jsApiList: ['hideMenuItems','hideAllNonBaseMenuItem'],
      });
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
  background-color: #fafafa;
}
.refund-tip {
  padding: 8px 12px;
  font-size: 12px;
  color: #999;
}
.refund-footer {
  margin: 24px 16px;
}
</style>
