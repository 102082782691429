<template>
  <div class="container">
    <nav-bar title="服务评价" left-arrow @click-left="goBack" />
    <van-cell-group title>
      <van-cell title="商品名称" :value="data.productTitle" />
      <van-cell title="价格" :value="`￥${data.paymentPrice}`" />
    </van-cell-group>
    <div class="rating-star">
      <span class="rating-label">评分：</span>
      <van-rate v-model="star" />
    </div>
    <div class="rating-tag">
      <van-tag
        round
        :type="tags.includes('态度好') ? 'warning' : 'default'"
        @click="onClick('态度好')"
      >
        态度好
      </van-tag>
      <van-tag
        round
        :type="tags.includes('服务周到') ? 'warning' : 'default'"
        @click="onClick('服务周到')"
      >
        服务周到
      </van-tag>
      <van-tag
        round
        :type="tags.includes('技术好') ? 'warning' : 'default'"
        @click="onClick('技术好')"
      >
        技术好
      </van-tag>
      <van-tag
        round
        :type="tags.includes('环境好') ? 'warning' : 'default'"
        @click="onClick('环境好')"
      >
        环境好
      </van-tag>
    </div>
    <van-cell-group>
      <van-field
        v-model="content"
        rows="3"
        autosize
        type="textarea"
        placeholder="亲，来评价下服务体验吧"
      />
    </van-cell-group>
    <div class="rate-footer">
      <v-button block @click="onSubmit">立即评价</v-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Cell, CellGroup, Field, Rate, Tag, Toast } from 'vant';
import { Button, NavBar } from '@/components';
import { mapState } from 'vuex';

export default {
  name: 'rate',
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [NavBar.name]: NavBar,
    [Rate.name]: Rate,
    [Tag.name]: Tag,
    [Toast.name]: Toast,
  },
  data() {
    return {
      data: {},
      star: 5,
      content: '',
      tags: [],
    };
  },
  computed: mapState(['code']),
  created() {
    axios({
      url: '/api/v1/h5/Orders',
      params: {
        id: this.$route.params.id,
      },
    }).then(({ data }) => {
      this.data = data;
    });
  },
  mounted() {
    if (this.code === 'NHJS') {
      // this.$api.hideWxMenu()
      this.hideWxMenu();
    }
  },
  methods: {
    onClick(value) {
      if (this.tags.includes(value)) {
        this.tags = this.tags.filter((x) => x !== value);
      } else {
        this.tags.push(value);
      }
    },
    onSubmit() {
      const { star, tags, content, data } = this;
      Toast.loading({ message: '加载中...', forbidClick: true });
      axios({
        url: `/api/v1/h5/Orders/${data.id}/Comment`,
        method: 'POST',
        data: { star, tags, content },
      }).then(({ code, msg }) => {
        if (code) {
          Toast(msg);
        } else {
          Toast.success('评价成功');
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    hideWxMenu() {
      if (/micromessenger/i.test(navigator.userAgent)) {
        let href = encodeURIComponent(window.location.href);
        axios({
          method: 'GET',
          url: `/wechat/generateShareSign?url=${href}`,
        }).then(({ code, count, data, msg }) => {
          if (code) {
            return;
          }
          this.getWxConfig(data);
          wx.ready(() => {
            wx.hideAllNonBaseMenuItem();

            // wx.hideMenuItems({
            //   menuList: [
            //     'menuItem:share:timeline',
            //     'menuItem:copyUrl',
            //     'menuItem:share:appMessage',
            //     'menuItem:share:qq',
            //     'menuItem:share:weiboApp',
            //     'menuItem:favorite',
            //     'menuItem:share:facebook',
            //     'menuItem:share:QZone',
            //     'menuItem:editTag',
            //     'menuItem:delete',
            //     'menuItem:copyUrl',
            //     'menuItem:originPage',
            //     'menuItem:readMode',
            //     'menuItem:openWithQQBrowser',
            //     'menuItem:openWithSafari',
            //     'menuItem:share:email',
            //     'menuItem:share:brand',
            //   ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
            // });
          });
          wx.error(function (res) {
            wx.hideAllNonBaseMenuItem();
            console.info(res);
          });
        });
      }
    },
    getWxConfig(data) {
      window.wx.config({
        debug: false,
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        jsApiList: ['hideMenuItems','hideAllNonBaseMenuItem'],
      });
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
  background-color: #fafafa;
}

.rating-star {
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding: 24px 16px;
  background-color: #fff;
}

.rating-label {
  font-size: 14px;
}

.rating-tag {
  background-color: #fff;
  padding: 0 16px 16px;
}

.rate-footer {
  margin: 24px 16px;
}

.van-tag {
  margin-right: 10px;
  padding: 0.4em 1em;
}
</style>
