import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const state = {
  code: '',
  token: '',
  color: '',
  title: '',
  pictures: {},
  services: [],
  setting: {},
  city: null,
  regions: {},
  projectId: '',
  openid: '',
  isNeedLogin: true,
  act:'',
  custPhone:'',
  nhjsOpenid:'',
  phone:'',
  custId: '',
};

const getters = {
  isLogin: (state) => {
    return state.token && !state.isNeedLogin;
  },
  serviceOptions: (state) =>
    state.services.map(({ productId, title }) => ({
      text: title,
      value: productId,
    })),
};

const mutations = {
  setToken(state, payload) {
    state.isNeedLogin = false;
    state.token = payload.token;
    state.act = payload.act;
    state.custPhone = payload.custPhone;
    state.nhjsOpenid = payload.nhjsOpenid;
    state.phone = payload.phone;
    state.custId = payload.custId;
    axios.defaults.headers.common['token'] = payload.token;
    if(payload.act){
      localStorage.setItem('act', payload.act);
    }
    if(payload.custPhone){
      localStorage.setItem('custPhone', payload.custPhone);
    }
    
    if(payload.nhjsOpenid){
      localStorage.setItem('nhjsOpenid', payload.nhjsOpenid);
    }
    
    localStorage.setItem(state.code || payload.code, payload.token);
  },
  setState(state, data) {
    state.customerPhone=data.customerPhone;
    state.code = data.code;
    state.color = data.themeColor;
    state.pictures = data.pictures;
    state.bigPictures = data.bigPictures;
    state.services = data.services;
    state.setting = {
      isBankcard: data.isBankcard,
    };
    state.title = data.title;
    state.projectId = data.id;
    state.showRemainCount = data.showRemainCount;
    state.isNeedLogin = data.isNeedLogin;
    state.regions = data.regions;
  },
  setCity(state, data) {
    state.city = { ...data };
  },

  setOpenId(state, data) {
    state.openid = data;
  },
};

const actions = {
  getProject({ commit }, code) {
    return axios({
      url: '/api/v1/h5/project',
      params: { code },
    }).then((res) => {
      if (res.code === 0) {
        commit('setState', res.data);
      }
      return res;
    });
  },
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {},
});
