<template>
  <van-empty :description="description" v-if="order">
    <div slot="image" v-if="order.status === 2">
      <van-icon name="checked" size="64" color="#07c160" />
      <p>支付成功</p>
    </div>
    <v-button block class="button" @click="goDetail">查看订单</v-button>
    <v-button block plain class="button" @click="goBack"> 返回首页 </v-button>
  </van-empty>
</template>

<script>
import { Icon, Empty, Toast } from 'vant';
import { Button } from '@/components';
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [Toast.name]: Toast,
  },
  computed: mapState(['code', 'nhjsOpenid', 'custPhone', 'act']),
  created: function () {
    Toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
      loadingType: 'spinner',
    });
    setTimeout(() => {
      this.fetchData();
    }, 1000);
  },
  data() {
    return {
      order: null,
      description: '',
    };
  },
  mounted(){
     if (this.code === 'NHJS') {
       this.hideWxMenu()
        // this.$api.hideWxMenu()
     }
  },
  methods: {
    fetchData() {
      axios({
        url: '/api/v1/h5/Orders',
        params: {
          id: this.$route.params.id,
        },
      }).then(({ data }) => {
        Toast.clear();
        this.order = data;
        if (data.status > 3) {
          this.description =
            '发券失败，当前支付方式不在活动范围内，付款金额稍后原路返回，如2个小时内未收到退款，请联系客服处理';
        } else if (data.status === 1) {
          this.description = '你的订单已提交，系统确认中';
        }
      });
    },
    goDetail() {
      this.$router.replace({
        name: 'orderDetail',
        params: { id: this.order.id },
      });

      // window.location.reload();
    },
    goBack() {
      if (this.code === 'NHJS') {
        let custPhone = localStorage.getItem('custPhone');
        let nhjsOpenid = localStorage.getItem('nhjsOpenid');
        let act = localStorage.getItem('act');
        this.$router.replace({
          name: 'home',
          query: {
            custPhone: custPhone,
            openid: nhjsOpenid,
            act: this.code === 'NHJS' ? act : '',
          },
        });
        window.location.reload();
      } 
      else if (this.code === 'NHHN'){
        let customerId = localStorage.getItem('customerId');

        window.location.href = 'https://h5.finequality1.com/NHHN?customerId=' + customerId;

        // this.$router.replace({
        //   name: 'home',
        //   query: {
        //     customerId: customerId,
        //   },
        // });
        // window.location.reload();
      }
      else {
        this.$router.replace({ name: 'home' });
        window.location.reload();
      }
    },
    hideWxMenu() {
      if (/micromessenger/i.test(navigator.userAgent)) {
        let href = encodeURIComponent(window.location.href);
        axios({
          method: 'GET',
          url: `/wechat/generateShareSign?url=${href}`,
        }).then(({ code, count, data, msg }) => {
          if (code) {
            return;
          }
          this.getWxConfig(data);
          wx.ready(() => {
            wx.hideAllNonBaseMenuItem();

            // wx.hideMenuItems({
            //   menuList: [
            //     'menuItem:share:timeline',
            //     'menuItem:copyUrl',
            //     'menuItem:share:appMessage',
            //     'menuItem:share:qq',
            //     'menuItem:share:weiboApp',
            //     'menuItem:favorite',
            //     'menuItem:share:facebook',
            //     'menuItem:share:QZone',
            //     'menuItem:editTag',
            //     'menuItem:delete',
            //     'menuItem:copyUrl',
            //     'menuItem:originPage',
            //     'menuItem:readMode',
            //     'menuItem:openWithQQBrowser',
            //     'menuItem:openWithSafari',
            //     'menuItem:share:email',
            //     'menuItem:share:brand',
            //   ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
            // });
          });
          wx.error(function (res) {
            wx.hideAllNonBaseMenuItem();
            console.info(res);
          });
        });
      }
    },
    getWxConfig(data) {
      window.wx.config({
        debug: false,
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        jsApiList: ['hideMenuItems','hideAllNonBaseMenuItem'],
      });
    },
  },
};
</script>

<style>
.van-empty__image {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.van-empty__description {
  text-align: center;
}
.van-empty__bottom {
  width: 88%;
}
</style>

<style scoped>
.button {
  margin-top: 16px;
}
</style>
