<template>
  <div class="container">
    <nav-bar title="我的订单" left-arrow @click-left="goBack" />
    <van-tabs v-model="type" @change="onChange" :color="color">
      <van-tab v-if="code === 'NHJS'" title="未支付" name="0" />
      <van-tab title="未消费" name="1" />
      <van-tab title="已消费" name="2" />
      <van-tab title="已退款" name="3" />
    </van-tabs>
    <div
      v-if="code === 'NHJS' && type === '0' && list.length > 0"
      style="
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 12px;
        color: #575757;
      "
    >
      未支付订单限15分钟内完成支付，否则领劵无效
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-panel
        v-for="item in list"
        :key="item.id"
        :title="`下单时间：${
          type === '0' ? item.createTime : item.paymentTime
        }`"
        :status="item.statusText"
        class="order-panel"
        :style="[
          {
            marginTop:
              code === 'NHJS' && type === '0' && list.length > 0
                ? '0px'
                : '10px',
          },
          {
            marginBottom:
              code === 'NHJS' && type === '0' && list.length > 0 ? '10px' : '0',
          },
        ]"
      >
        <van-card
          :price="`${item.paymentPrice.toFixed(2)}`"
          :title="item.projectTitle"
          :thumb="item.pictures"
          @click="goDetail(item.id)"
        />
        <div slot="footer" v-if="item.status === 1 || item.status === 3">
          <v-button
            size="small"
            v-if="item.status === 1"
            @click="onCancel(item.id)"
          >
            取消订单
          </v-button>
          <v-button
            size="small"
            v-if="item.status === 1"
            :to="`/${code}/Pay/${item.serviceId}?projectId=${item.projectId}`"
          >
            立即支付
          </v-button>
          <!-- <v-button plain size="small" v-if="item.status === 21" :to="`/order/${item.id}/refund`">退款</v-button>-->
          <v-button
            size="small"
            v-if="item.status === 3"
            @click="goRate(item.id)"
          >
            评价
          </v-button>
          <v-button
            size="small"
            v-if="item.status === 3"
            @click="goComplain(item.id)"
          >
            投诉
          </v-button>
        </div>
      </van-panel>
    </van-list>
  </div>
</template>

<script>
import axios from 'axios';
import { Card, List, Tab, Tabs, Panel } from 'vant';
import { Button, NavBar } from '@/components';
import { mapState } from 'vuex';

export default {
  name: 'orderList',
  components: {
    [Button.name]: Button,
    [Card.name]: Card,
    [List.name]: List,
    [NavBar.name]: NavBar,
    [Panel.name]: Panel,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      type: 2,
      page: 1,
      size: 10,
      list: [],
      loading: false,
      finished: false,
      status: {
        21: '未消费',
        22: '已使用',
        25: '退款中',
        26: '退款中',
        27: '退款完成',
        28: '退款失败',
      },
    };
  },
  computed: mapState(['color', 'projectId', 'code']),
  mounted() {
    if (this.code === 'NHJS') {
      // this.$api.hideWxMenu()
      this.hideWxMenu();
    }
  },
  methods: {
    onLoad() {
      const { page, size, type, projectId } = this;
      axios({
        url: '/api/v1/h5/orders',
        params: { projectId, page, size, status: type },
      }).then(({ count, data }) => {
        this.loading = false;
        if (this.page * this.size >= count) {
          this.finished = true;
        }
        this.page++;
        this.list = this.list.concat(data);
      });
    },
    onChange(name) {
      this.page = 1;
      this.list = [];
      this.type = name;
      this.loading = true;
      this.finished = false;
      this.onLoad();
    },
    onCancel(id) {
      axios({
        url: `/api/v1/h5/orders/${id}/cancel`,
        method: 'POST',
      }).then(({ code, msg }) => {
        if (code) {
          Toast(msg);
        } else {
          let index = this.list.findIndex((item) => item.id === id);

          if (index > -1) {
            this.list.splice(index, 1);
          }
          Toast.success('取消成功');
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    goDetail(id) {
      this.$router.push({ name: 'orderDetail', params: { id: id } });
    },
    goRate(id) {
      this.$router.push({ name: 'orderRate', params: { id: id } });
    },
    goComplain(id) {
      this.$router.push({ name: 'orderComplain', params: { id: id } });
    },
    hideWxMenu() {
      if (/micromessenger/i.test(navigator.userAgent)) {
        let href = encodeURIComponent(window.location.href);
        axios({
          method: 'GET',
          url: `/wechat/generateShareSign?url=${href}`,
        }).then(({ code, count, data, msg }) => {
          if (code) {
            return;
          }
          this.getWxConfig(data);
          wx.ready(() => {
            wx.hideAllNonBaseMenuItem();

            // wx.hideMenuItems({
            //   menuList: [
            //     'menuItem:share:timeline',
            //     'menuItem:copyUrl',
            //     'menuItem:share:appMessage',
            //     'menuItem:share:qq',
            //     'menuItem:share:weiboApp',
            //     'menuItem:favorite',
            //     'menuItem:share:facebook',
            //     'menuItem:share:QZone',
            //     'menuItem:editTag',
            //     'menuItem:delete',
            //     'menuItem:copyUrl',
            //     'menuItem:originPage',
            //     'menuItem:readMode',
            //     'menuItem:openWithQQBrowser',
            //     'menuItem:openWithSafari',
            //     'menuItem:share:email',
            //     'menuItem:share:brand',
            //   ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
            // });
          });
          wx.error(function (res) {
            wx.hideAllNonBaseMenuItem();
            console.info(res);
          });
        });
      }
    },
    getWxConfig(data) {
      window.wx.config({
        debug: false,
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        jsApiList: ['hideMenuItems','hideAllNonBaseMenuItem'],
      });
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
  background-color: #fafafa;
}
.order-panel {
  margin-top: 10px;
  background-color: #fff;
}
.van-card {
  background-color: #fff;
}
.van-panel__footer {
  text-align: right;
}
.van-button--default {
  margin-left: 5px;
}
.van-card__title {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: normal;
}
.van-panel__header-value {
  flex: none;
}
</style>
