<template>
  <section class="container">
    <nav-bar title="网点查询" left-arrow @click-left="goBack" />

    <van-dropdown-menu :active-color="color">
      <van-dropdown-item :title="area" ref="item">
        <div class="other-city">
          <v-button size="small" @click="goCity">选择其他城市</v-button>
        </div>
        <van-cell-group>
          <van-cell
            v-for="item in region"
            :key="item.value"
            :title="item.text"
            @click="onClick(item)"
          >
            <van-icon
              v-if="item.value === areaId"
              :color="color"
              name="success"
              slot="right-icon"
              style="line-height: inherit"
            />
          </van-cell>
        </van-cell-group>
      </van-dropdown-item>
      <van-dropdown-item
        v-model="productId"
        :options="serviceOptions"
        @change="onChange"
      />
      <van-dropdown-item
        :value="0"
        :options="[{ text: '默认排序', value: 0 }]"
      />
    </van-dropdown-menu>

    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-card
        v-for="item in list"
        :key="item.id"
        :title="item.title"
        class="card"
      >
        <div slot="desc" class="address">
          {{
            item.provinceTitle + item.cityTitle + item.areaTitle + item.address
          }}
        </div>
        <div slot="tags" class="meta">
          <div class="clock">
            <van-icon name="clock" color="#666" />
            <span class="meta-value">
              营业时间：{{ item.startWorkTime }} ~ {{ item.endWorkTime }}
            </span>
          </div>
          <div class="location">
            <van-icon name="location" color="#666" />
            <span class="meta-value">{{ item.distance }}</span>
          </div>
        </div>
        <div slot="footer">
          <v-button
            size="small"
            icon="phone"
            :url="`tel:${item.linkMobile || item.linkTel}`"
          >
            联系
          </v-button>
          <v-button
            plain
            size="small"
            icon="map-marked"
            @click="navigate(item)"
          >
            导航
          </v-button>
        </div>
        <img slot="thumb" :src="item.shopImgUrl" class="card-img" />
      </van-card>
    </van-list>
  </section>
</template>

<script>
import {
  Card,
  Cell,
  CellGroup,
  Dialog,
  DropdownMenu,
  DropdownItem,
  Icon,
  List,
} from 'vant';
import axios from 'axios';
import { Button, NavBar } from '@/components';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'network',
  components: {
    [Button.name]: Button,
    [Card.name]: Card,
    [Cell.name]: Cell,
    [Dialog.name]: Dialog,
    [CellGroup.name]: CellGroup,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Icon.name]: Icon,
    [List.name]: List,
    [NavBar.name]: NavBar,
  },
  data() {
    return {
      page: 1,
      list: [],
      loading: true,
      finished: false,
      productId: '',
      area: '请选择城市',
      areaId: '',
      region: [],
      location: { lat: '', lng: '' },
    };
  },
  computed: {
    ...mapState(['color', 'city', 'projectId', 'regions', 'code']),
    ...mapGetters(['serviceOptions']),
  },
  mounted() {
    this.productId = this.$route.query.productId;
    if (this.city) {
      this.init();
    } else {
      this.getCurrentPosition();
    }
    if (this.code === 'NHJS') {
      // this.$api.hideWxMenu()
      this.hideWxMenu();
    }
  },
  methods: {
    init() {
      const { city_list, county_list } = this.regions;
      let code = this.city.code.slice(0, 4);

      this.region = Object.keys(county_list)
        .filter((x) => x.includes(code))
        .map((value) => ({ text: county_list[value], value }));
      this.area = this.city.name || "请选择城市";
      this.areaId = this.city.code;

      this.onLoad();
    },
    getWxConfig(data) {
      window.wx.config({
        debug: false,
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        jsApiList: ['getLocation', 'hideMenuItems'],
      });
    },
    getLocationAddress(lat, lng) {
      axios({
        url: `/account/Geocoder?location=${lat},${lng}`,
        method: 'GET',
      }).then(({ status, result }) => {
        if (status === 0) {
          this.$store.commit('setCity', {
            code: result.ad_info.adcode.replace(/^(\d{4})\d{2}$/, '$100'),
            name: result.ad_info.city,
          });
          this.init();
        }
      });
    },
    getCurrentPosition() {
      if (/micromessenger/i.test(navigator.userAgent)) {
        let href = decodeURIComponent(window.location.href);
        axios({
          method: 'GET',
          url: `/wechat/generateShareSign?url=${href}`,
        }).then(({ code, count, data, msg }) => {
          if (code) {
            return getLocationError();
          }
          this.getWxConfig(data);
          let that = this;
          wx.ready(() => {
            wx.getLocation({
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: (res) => {
                that.location.lat = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                that.location.lng = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                that.getLocationAddress(res.latitude, res.longitude);
              },
              fail: (err) => {
                getLocationError();
              },
            });
          });
        });
      } else {
        const geolocation = new qq.maps.Geolocation(
          'NFMBZ-6RUWS-LJDOP-62EZB-PAYKO-XOFA4',
          'yunzhi'
        );

        geolocation.getLocation(({ lat, lng, adcode, city }) => {
          this.location = { lat, lng };
          this.$store.commit('setCity', {
            code: (adcode + '').replace(/^(\d{4})\d{2}$/, '$100'),
            name: city,
          });
          this.init();
        }, getLocationError);
      }
      let that = this;
      function getLocationError() {
        Dialog.alert({
          title: '提示',
          message: '未能定位到你的位置信息，请选择城市',
        }).then(() => {
          that.goCity();
          // on close
        });
      }
    },
    onLoad() {
      const { page, productId, areaId, projectId, location } = this;

      axios({
        url: '/api/v1/h5/network',
        params: {
          page,
          productId,
          projectId,
          areaId: areaId,
          ...location,
        },
      }).then(({ count, data }) => {
        this.page++;
        this.list.push(...data);
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= count) {
          this.finished = true;
        }
      });
    },
    onClick(item) {
      this.area = item.text;
      this.areaId = item.value;
      this.$refs.item.toggle();
      this.onChange();
    },
    onChange() {
      this.page = 1;
      this.list = [];
      this.loading = true;
      this.finished = false;
      this.onLoad();
    },
    goCity() {
      this.$router.push({ name: 'city', query: { productId: this.productId } });
    },
    goBack() {
      this.$router.go(-1);
    },
    navigate(item) {
      window.location.href = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${item.lat},${item.lng};title:${item.title};addr:${item.address}`;
    },
    hideWxMenu() {
      if (/micromessenger/i.test(navigator.userAgent)) {
        let href = encodeURIComponent(window.location.href);
        axios({
          method: 'GET',
          url: `/wechat/generateShareSign?url=${href}`,
        }).then(({ code, count, data, msg }) => {
          if (code) {
            return;
          }
          this.getWxConfig(data);
          wx.ready(() => {
            wx.hideAllNonBaseMenuItem();

            // wx.hideMenuItems({
            //   menuList: [
            //     'menuItem:share:timeline',
            //     'menuItem:copyUrl',
            //     'menuItem:share:appMessage',
            //     'menuItem:share:qq',
            //     'menuItem:share:weiboApp',
            //     'menuItem:favorite',
            //     'menuItem:share:facebook',
            //     'menuItem:share:QZone',
            //     'menuItem:editTag',
            //     'menuItem:delete',
            //     'menuItem:copyUrl',
            //     'menuItem:originPage',
            //     'menuItem:readMode',
            //     'menuItem:openWithQQBrowser',
            //     'menuItem:openWithSafari',
            //     'menuItem:share:email',
            //     'menuItem:share:brand',
            //   ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
            // });
          });
          wx.error(function (res) {
            wx.hideAllNonBaseMenuItem();
            console.info(res);
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
  background-color: #fafafa;
}
.card {
  margin: 10px;
  border-radius: 6px;
  background-color: #fff;
}
.card-img {
  width: 88px;
  height: 88px;
  margin-right: 8px;
  object-fit: cover;
}
.address {
  margin-top: 8px;
  color: #666;
}
.meta {
  margin-top: 6px;
}
.meta-value {
  margin-left: 4px;
  color: #666;
}
.clock,
.location {
  display: flex;
  align-items: center;
}

.van-card__title {
  font-size: 16px;
  font-weight: normal;
}

.other-city {
  padding: 12px 0;
  text-align: center;
}
</style>
