<template>
  <van-button v-bind="$attrs" v-on="$listeners" :color="color">
    <slot></slot>
  </van-button>
</template>

<script>
import { Button } from 'vant';
import { mapState } from 'vuex';

export default {
  name: 'v-button',
  components: {
    [Button.name]: Button,
  },
  computed: mapState(['color']),
};
</script>
